import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CognitoAuthService } from '../services/cognito-auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserRoleGuard implements CanActivate {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.cognitoAuthService.loggedInConnect$.pipe(
      map((user) => {
        if (!user) {
          this.router.navigate(['secure']);
          return false;
        }

        const isAuthorised = (user.userRoles || []).some((r) =>
          ['clientAdmin', 'projectUser'].includes(r)
        );

        if (!isAuthorised) {
          this.router.navigate(['/search']);
          return false;
        }

        return isAuthorised;
      })
    );
  }
}
