<div fxFlex fxLayout="column" fxLayoutAlign="center center" class="auth-dialog">

  <button mat-icon-button tabindex="-1" (click)="closeDialog()" class="close-button" data-test-id="auth-dialog-close">
    <mat-icon>close</mat-icon>
  </button>

  <p class="header" data-test-id="auth-dialog-header"> You need to be logged in to see this! </p>

  <div>
    <a mat-flat-button color="primary" routerLink='/secure' data-test-id="auth-dialog-login">
      Login
    </a>
  </div>

  <p data-test-id="auth-dialog-instruction-text">
    Click to login, or contact your project manager to set up your account.
  </p>

  <p data-test-id="auth-dialog-faq-text">
    Any questions? Visit our
    <a href="https://www.techspert.com/portal-faqs" target="_blank" data-test-id="auth-dialog-faq-link"> help portal.</a>
  </p>

</div>