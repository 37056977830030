import { Pipe, PipeTransform } from '@angular/core';
import { IExpertPhases } from '../../../shared/state/expert.service';

@Pipe({
  name: 'portalPhase',
})
export class PortalPhasePipe implements PipeTransform {
  transform(portalPhase: keyof IExpertPhases): string {
    return {
      newExperts: 'Shortlisted',
      acceptedExperts: 'Upcoming Connection',
      completedConnections: 'Completed Connection',
      declinedExperts: 'Rejected Profile',
    }[portalPhase];
  }
}
