import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss'],
})
export class AuthDialogComponent {
  constructor(private dialogRef: MatDialogRef<AuthDialogComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
