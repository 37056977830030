import { Component } from '@angular/core';

export type PopupStages = 'sales' | 'enquiry' | 'success' | 'fail';

@Component({
  selector: 'app-omnisearch-sales-popup',
  template: `<app-omnisearch-sales-pitch
      *ngIf="popupPage === 'sales'"
      (popupPage)="changePopupPage($event)"
    >
    </app-omnisearch-sales-pitch>

    <app-omnisearch-sales-enquiry-form
      *ngIf="popupPage === 'enquiry'"
      (popupPage)="changePopupPage($event)"
    ></app-omnisearch-sales-enquiry-form>

    <app-omnisearch-enquiry-result
      *ngIf="['success', 'fail'].includes(popupPage)"
      [requestStatus]="popupPage"
    >
    </app-omnisearch-enquiry-result>`,
})
export class OmnisearchSalesPopupComponent {
  popupPage: PopupStages = 'sales';

  changePopupPage(page: PopupStages): void {
    this.popupPage = page;
  }
}
