<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 class="primary-color" data-test-id="expert-no-show-dialog-title">Report Expert No Show</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon>clear</mat-icon>
  </button>
</div>

<div *ngIf="stage === 'confirmation'" fxLayout="column">
  <p class="instructions">
    Expert not yet joined the call? Click 'reply all' in your calendar invite to inform us and a member of the techspert
    team will be in touch immediately (during the hours {{officeHours.start | availabilityTime}} - {{officeHours.end
    |
    availabilityTime}} {{timezoneAbbr}}). They will:
  </p>
  <ol class="instructions">
    <li>Confirm receipt of your alert that the expert isn't yet on the call</li>
    <li>Call the expert directly</li>
    <li>Follow up with an email to the expert</li>
    <li>Update you via email: either the expert will be joining momentarily, or we may need to reschedule your call</li>
  </ol>
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" fxFill>
    <button mat-stroked-button color="primary" mat-dialog-close fxFlex="50%"
      data-test-id="expert-no-show-dialog-close-button">Close</button>
    <button mat-flat-button color="primary" (click)="sendRequest()" fxFlex="50%"
      data-test-id="expert-no-show-dialog-confirm-button">Confirm</button>
  </div>
</div>
<app-loader *ngIf="stage === 'loading'"></app-loader>
<div *ngIf="stage === 'completed'" fxLayout="column">
  <p class="instructions" data-test-id="expert-no-show-dialog-result-text">
    Thank you for informing us. We will respond to you as soon as possible.
  </p>
  <button mat-flat-button color="primary" mat-dialog-close
    data-test-id="expert-no-show-dialog-understood-button">Understood</button>
</div>
<div *ngIf="stage === 'error'" fxLayout="column">
  <p class="instructions" data-test-id="expert-no-show-dialog-result-text">
    Error processing request, please contact support at <a
      href="mailto:callsupport@techspert.com">callsupport@techspert.com</a>.
  </p>
  <button mat-flat-button color="primary" mat-dialog-close
    data-test-id="expert-no-show-dialog-understood-button">Understood</button>
</div>