<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" data-test-id="request-time-change-form-dialog">
  <h3 class="text-blue" data-test-id="request-time-change-form-title">Request a consultation time</h3>
  <button mat-icon-button mat-dialog-close>
    <mat-icon data-test-id="request-time-change-form-close">clear</mat-icon>
  </button>
</div>

<h3 *ngIf="error" style="margin: auto">{{error}}</h3>

<ng-container *ngIf="!error">
  <mat-dialog-content fxLayout="column">

    <form fxFlex [formGroup]="availabiltyForm" fxLayout="column">
      <div fxLayout="row" fxLayoutGap="8px">
        <div data-test-id="request-time-change-form-instructions">
          <p class="instructions">
            Please provide a few times you are available to connect with <b>{{expertName}}</b>. We'll do everything we
            can to accommodate your request.
          </p>
          <p class="instructions" *ngIf="expertTimezone">
            <b class="text-blue">{{expertName}} is in {{expertTimezone}}</b>. Please try to bear this in mind when
            suggesting times.
          </p>
        </div>

        <div>
          <mat-form-field fxFlex>
            <mat-label>Your timezone</mat-label>
            <mat-select formControlName="timezone" data-test-id="request-time-change-user-timezone-select">
              <mat-option *ngFor="let option of tzOptions" [value]="option"
                data-test-id="request-time-change-user-timezone-select-option">
                {{option}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <table mat-table [dataSource]="slots" fxFlex formGroupName="slots">

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field appearance="outline">
              <mat-label>Date</mat-label>
              <input matInput type="date" formControlName="date" [min]="minDate"
                data-test-id="request-time-change-form-date">
              <mat-error [style.padding-top]="'8px'" data-test-id="request-time-change-form-date-error">Date cannot be
                in the past</mat-error>
            </mat-form-field>
          </th>

          <td mat-cell *matCellDef="let element" data-test-id="request-time-change-proposed-slots-table-date">
            {{element.client.date | date}}
          </td>
        </ng-container>

        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field appearance="outline">
              <mat-label>Start time</mat-label>
              <input matInput type="time" formControlName="startTime" data-test-id="request-time-change-form-start-time">
            </mat-form-field>
          </th>

          <td mat-cell *matCellDef="let element" data-test-id="request-time-change-proposed-slots-table-start-time">
            {{element.client.start}}
            <span class="text-dim" [matTooltip]="element.expert.label">({{element.expert.start}})</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="endTime">
          <th mat-header-cell *matHeaderCellDef>
            <mat-form-field appearance="outline">
              <mat-label>End time</mat-label>
              <input matInput type="time" formControlName="endTime" data-test-id="request-time-change-form-end-time">
            </mat-form-field>
          </th>

          <td mat-cell *matCellDef="let element" data-test-id="request-time-change-proposed-slots-table-end-time">
            {{element.client.end}}
            <span class="text-dim" [matTooltip]="element.expert.label">({{element.expert.end}})</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="opts">
          <th mat-header-cell *matHeaderCellDef>
            <button mat-fab type="button" (click)="submitDates()"
              data-test-id="request-time-change-form-add-proposed-slots-button">
              <mat-icon>add</mat-icon>
            </button>
          </th>

          <td mat-cell *matCellDef="let element; let idx = index">
            <button mat-icon-button type="button" (click)="remove(idx)"
              data-test-id="request-time-change-remove-proposed-slots-button">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          data-test-id="request-time-change-proposed-slots-table-row"></tr>
      </table>

      <mat-form-field appearance="outline">
        <mat-label>Additional comments</mat-label>
        <textarea matInput placeholder="Additional comments" formControlName="notes" class="free-text-area"
          data-test-id="request-time-change-form-additional-comments"></textarea>
        <mat-error [style.padding-top]="'8px'" data-test-id="request-time-change-form-additional-comments-error">Please add
          a comment</mat-error>
      </mat-form-field>
    </form>

    <hr>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button fxFlex type="button" mat-stroked-button mat-dialog-close color="primary" class="primaryColor-border">
      Close window
    </button>
    <button fxFlex type="submit" mat-flat-button color="primary" (click)="sendRequest()"
      data-test-id="request-time-change-form-send-request">
      Send request
    </button>
  </mat-dialog-actions>
</ng-container>