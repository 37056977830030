<app-expert-filter class="experts-filter" [experts]="experts" (filterExperts)="filterExperts($event)">
</app-expert-filter>

<div class="expert-list" data-test-id="expert-list-expert-list">
  <app-expert-table *ngIf="expertsFiltered.length" [experts]="expertsFiltered" [expertLoadingIds]="expertLoadingIds"
    [viewStage]="viewStage" [opportunity]="opportunity" [isFileDownloadAuthorised]="isFileDownloadAuthorised"
    [isClientUser]="isClientUser" [isExpertApprovalRequired]="isExpertApprovalRequired">
  </app-expert-table>

  <div class="empty-message" *ngIf="!expertsFiltered.length && !onHoldExperts.length">
    <div class="hex-container">
      <i class="material-icons">person_outline</i>
    </div>
    <h4>No experts</h4>
  </div>

  <ng-container *ngIf="onHoldExperts.length">
    <div class="on-hold" data-test-id="expert-list-on-hold-banner">
      <div class="badge" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px">
        <app-on-hold-svg></app-on-hold-svg>
        <span>On hold</span>
      </div>
    </div>

    <app-expert-table class="gray" [experts]="onHoldExperts" [expertLoadingIds]="expertLoadingIds"
      [viewStage]="viewStage" [opportunity]="opportunity" [isFileDownloadAuthorised]="isFileDownloadAuthorised"
      [isClientUser]="isClientUser" [isExpertApprovalRequired]="isExpertApprovalRequired">
    </app-expert-table>
  </ng-container>
</div>