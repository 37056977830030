import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '@techspert-io/auth';
import { Subject } from 'rxjs';
import { scan, shareReplay, startWith, tap } from 'rxjs/operators';

const tabStorageKey = 'opportunitySelectedTabIndex';
const expertsStorageKey = 'expertsExpanded';

type NavigationState = Record<string, boolean>;

@Injectable()
export class OpportunityNavigationService {
  private tabState: number;
  private navigationState$ = new Subject<NavigationState>();

  private initState: NavigationState = JSON.parse(
    this.storage.getItem(expertsStorageKey) || '{}'
  );

  state$ = this.navigationState$.pipe(
    scan((acc, curr) => ({ ...acc, ...curr }), this.initState),
    tap((experts) => this.setLocalStorageExperts(experts)),
    startWith(this.initState),
    shareReplay(1)
  );

  get selectedTab(): number {
    return this.tabState === 0 || this.tabState
      ? this.tabState
      : +(this.storage.getItem(tabStorageKey) || 0);
  }

  constructor(@Inject(LOCAL_STORAGE) private storage: Storage) {}

  setNavigationState(selectedExperts: NavigationState): void {
    this.navigationState$.next(selectedExperts);
  }

  setTabState(oppTabState: number): void {
    this.tabState = oppTabState;
    this.storage.setItem(tabStorageKey, oppTabState.toString());
  }

  private setLocalStorageExperts(experts: NavigationState): void {
    const selectedExperts = Object.entries(experts)
      .filter(([, v]) => v)
      .reduce<NavigationState>((acc, [k, v]) => ({ ...acc, [k]: v }), {});

    this.storage.setItem(expertsStorageKey, JSON.stringify(selectedExperts));
  }
}
