export const environment = {
  production: false,
  base: 'https://exp.portal.techspert.com',
  connectApiBaseUrl: 'https://exp.api.connect.techspert.io',
  filestoreEndpoint: 'https://exp.api.connect.techspert.io/filestore',
  screenerEndpoint: 'https://exp.screener.techspert.com',
  gaMeasurementId: 'G-LWE3MB2DCQ',
  auth0: {
    domain: 'exp.id.techspert.com',
    clientId: '8ANm9RwocDRNwfx7xbDMv7ZHS6iLsmso',
    authorizationParams: {
      connection: 'All-Passwordless-Users',
      audience: 'https://exp.api.techspert.io',
      scope: 'openid profile email offline_access',
    },
  },
};
