import { IUser } from '../models/user';

export const userMock: IUser = {
  id: 'userId',
  firstName: 'f',
  lastName: 'l',
  email: 'test@example.com',
  userType: 'Client',
  clients: {
    clientId: {
      clientName: 'clientName',
      features: {
        omnisearchEnabled: false,
      },
      contractStatus: 'Contract',
    },
  },
  userRoles: ['clientAdmin'],
  country: 'US',
  timezone: { abbr: '', name: '', offset: '' },
  active: true,
  dateCreated: 123,
  lastUpdated: 1234,
};
