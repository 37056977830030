<div class="statistics mat-typography" fxLayout="column" fxLayout.gt-sm="row"
    *ngIf="expertStore.experts$ | async as observables">
    <mat-card class="stat-card" style="background-color: #57CF69" (click)="adjustTab(1)" fxLayout="column"
        fxLayoutGap="4px" fxLayoutAlign="space-between center">
        <div class="title">New Profiles to review</div>
        <div class="central-container">
            {{observables.newExperts.length}}
        </div>
        <div fxLayout="row">
            <div class="review">Review your new profiles</div>
            <mat-icon>arrow_forward</mat-icon>
        </div>
    </mat-card>
    <mat-card class="stat-card" style="background-color: #1e2b4e" (click)="adjustTab(2)" fxLayout="column"
        fxLayoutGap="4px" fxLayoutAlign="space-between center">
        <div class="title">Scheduled Connections</div>
        <div class="central-container">
            {{observables.acceptedExperts.length}}
        </div>
        <div fxLayout="row">
            <div class="review">Review scheduled profiles</div>
            <mat-icon>arrow_forward</mat-icon>
        </div>
    </mat-card>
    <mat-card class="stat-card" style="background-color: #B5BFCC" (click)="adjustTab(3)" fxLayout="column"
        fxLayoutGap="4px" fxLayoutAlign="space-between center">
        <div class="title">Completed Connections</div>
        <div class="central-container">
            {{observables.completedConnections.length}}
        </div>
        <div fxLayout="row">
            <div class="review">View completed profiles</div>
            <mat-icon>arrow_forward</mat-icon>
        </div>
    </mat-card>
</div>

<div fxLayout="row" fxLayoutAlign="center">
    <mat-card *ngIf="emptyExperts && showOmnisearchBanner" class="omnisearch-banner primaryColorBG"
        data-test-id="geographic-progress-omnisearch-banner">
        Want a head start on scheduling expert calls? Use
        <b class="omnisearch secondaryColor" (click)="openOmnisearch.emit()">Omnisearch</b>
        to see if your colleagues have spoken with similar experts
    </mat-card>
</div>

<div class="progress-container" fxLayout="column" fxLayoutGap="8px">
    <img *ngIf="emptyExperts" class="empty-progress" src="./../../../../assets/images/empty-progress.png">

    <div class="progress-header" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!emptyExperts">
        <h4>Below is your project progress broken down by segments</h4>
        <button mat-stroked-button color="primary" fxLayout="row" class="export primaryColor-border"
            (click)="downloadProgressCsv()">
            <mat-icon class="secondaryColor">ic_file_download</mat-icon>
            <span>Export progress</span>
        </button>
    </div>
    <table class="mat-typography" mat-table *ngIf="!emptyExperts" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="summary">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Segment Summaries
            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-stroked-button color="primary" [disabled]="!element.transcriptionSummaryKey" class="export"
                    (click)="downloadSummary(element)">
                    <mat-icon class="secondaryColor">ic_file_download</mat-icon>
                    <span>{{element.transcriptionSummaryKey ? 'Download' : 'Processing'}}</span>
                </button>
            </td>
            <td mat-footer-cell *matFooterCellDef>TOTAL</td>
        </ng-container>
        <ng-container matColumnDef="geography">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Geography
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.geography}}
            </td>
            <td mat-footer-cell *matFooterCellDef>{{ opportunity.createTranscriptSummary?.segment ? '' : 'TOTAL' }}</td>
        </ng-container>
        <ng-container matColumnDef="segment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                Profile Type
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.segment}}
            </td>
            <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="screening">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Screening</th>
            <td mat-cell *matCellDef="let element">
                <div class="center-align">{{element.screening}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <div class="center-align">
                    <div class="total">
                        {{opportunity.opportunitySegmentStatsTotal?.screening}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="inPortal">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>New</th>
            <td mat-cell *matCellDef="let element">
                <div class="center-align">{{element.inPortal}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <div class="center-align">
                    <div class="total">
                        {{opportunity.opportunitySegmentStatsTotal?.inPortal}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="scheduled">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <span>Upcoming</span>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center-align">{{element.scheduled}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <div class="center-align">
                    <div class="total">
                        {{opportunity.opportunitySegmentStatsTotal?.scheduled}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="completed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Completed</th>
            <td mat-cell *matCellDef="let element">
                <div class="center-align">{{element.completed}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <div class="center-align">
                    <div class="total">
                        {{opportunity.opportunitySegmentStatsTotal?.completed}}
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="confirmed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header
                matTooltip="Confirmed connections is the total number of scheduled and completed calls">
                <b style="color: black">Confirmed</b>
                <mat-icon>info_outline</mat-icon>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center-align">{{element.confirmed}}</div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <div class="center-align">
                    <div class="total">
                        <b>{{opportunity.opportunitySegmentStatsTotal?.confirmed}}</b>
                    </div>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="target">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                <b style="color: black">Target</b>
            </th>
            <td mat-cell *matCellDef="let element">
                <div class="center-align">
                    <div class="center-align">
                        <div class="total">
                            <b>{{element.target}}</b>
                        </div>
                    </div>
                </div>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <div class="center-align">
                    <div class="total">
                        <b>{{opportunity.opportunitySegmentStatsTotal?.target}}</b>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="body-row"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns" class="footer-row"></tr>
    </table>
</div>