<div fxLayout="column" fxLayoutGap="4px" data-test-id="expert-timeline">
  <div *ngFor="let item of items; let last = last" class="timeline-item" [ngClass]="item.class"
    data-test-id="expert-timeline-item">
    <ng-container [ngTemplateOutlet]="expertTimelineItemTmpl"
      [ngTemplateOutletContext]="{icon: item.icon, title: item.title, dateTime: item.dateTime, name: item.name, showTimeline: !last}"></ng-container>
  </div>
</div>

<ng-template #expertTimelineItemTmpl let-icon="icon" let-title="title" let-dateTime="dateTime" let-name="name"
  let-showTimeline="showTimeline">
  <div fxLayout="row" fxLayoutGap="8px" fxFlex="100%">
    <div fxLayout="column" class="timeline-col">
      <mat-icon data-test-id="expert-timeline-item-icon">
        {{icon}}
      </mat-icon>
      <div *ngIf="showTimeline" fxLayout="row">
        <div fxFlex="51%" class="vertical-line">
        </div>
      </div>
    </div>

    <div fxLayout="column" fxFlex="100%" class="detail-col">
      <div class="timeline-title" data-test-id="expert-timeline-item-title">
        {{title}}
      </div>

      <div *ngIf="name" class="timeline-name" data-test-id="expert-timeline-item-name">
        {{name}}
      </div>

      <div *ngIf="dateTime" class="timeline-datetime" data-test-id="expert-timeline-item-datetime">
        {{dateTime | date: 'EEE dd MMMM YYYY'}},
        {{dateTime | date: 'shortTime'}}
      </div>
    </div>
  </div>
</ng-template>