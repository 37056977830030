import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { OmnisearchModule } from '@techspert-io/omnisearch';
import { TxpLogoComponent } from './components/txp-logo/txp-logo.component';
import { PortalFAQLinkDirective } from './directives/portal-faqlink.directive';
import { TopBarComponent } from './top-bar.component';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    OmnisearchModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    RouterModule,
  ],
  exports: [TopBarComponent],
  declarations: [TopBarComponent, TxpLogoComponent, PortalFAQLinkDirective],
})
export class TopBarModule {}
