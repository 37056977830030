<div class="nav primaryColorBG" fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center">
  <app-txp-logo (click)="navigateRoot()" *ngIf="(!showOmnisearch || innerWidth > 600)" data-test-id="txp-logo">
  </app-txp-logo>

  <app-omnisearch class="omnisearch" *ngIf="showOmnisearch && isOmnisearchAuthorised">
  </app-omnisearch>

  <app-omnisearch-sales-incentive class="omnisearch" *ngIf="omnisearchSalesCarrot"></app-omnisearch-sales-incentive>

  <div class="right-nav-elements" *ngIf="(loggedInAuth0 | async) === false">
    <button mat-icon-button [matMenuTriggerFor]="noAuthMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #noAuthMenu="matMenu">
      <button mat-menu-item [routerLink]="['/secure']">
        <mat-icon>login</mat-icon>
        <span>Login</span>
      </button>

      <button mat-menu-item appPortalFAQLink>
        <mat-icon>help_outline</mat-icon>
        <span>Help</span>
      </button>
    </mat-menu>
  </div>

  <div class="right-nav-elements" *ngIf="(!showOmnisearch || innerWidth > 600) && loggedInAuth0 | async">
    <button mat-icon-button *ngIf="isOmnisearchAuthorised && !showOmnisearch" (click)="showOmni()">
      <mat-icon>search</mat-icon>
    </button>

    <button mat-icon-button [matMenuTriggerFor]="authMenu">
      <mat-icon>person</mat-icon>
    </button>

    <mat-menu #authMenu="matMenu">
      <div class="top-portion" *ngIf="loggedInUser">
        <div class="icon-edit-container">
          <div class="profile-icon primaryColorBG">
            {{loggedInUser.firstName[0] | uppercase}}{{loggedInUser.lastName[0] | uppercase}}
          </div>
        </div>
        <div class="name" [attr.user-id]="loggedInUser.id">
          Hi {{loggedInUser.firstName}}
        </div>
      </div>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Log Out</span>
      </button>

      <button mat-menu-item appPortalFAQLink>
        <mat-icon>help_outline</mat-icon>
        <span>Help</span>
      </button>
    </mat-menu>
  </div>
</div>