export type UserType = 'PM' | 'Client' | 'Expert' | 'Service-Provider';
export type UserRoleType = 'projectUser' | 'clientAdmin';

export interface IClientFeature {
  omnisearchEnabled: boolean;
  omnisearchGlobalEnabled?: boolean;
  salesCarrotEnabled?: boolean;
  expertApprovalRequired?: boolean;
}

export interface IUserClient {
  clientName: string;
  features: IClientFeature;
  contractStatus:
    | 'Prospect'
    | 'Qualifying'
    | 'Qualified'
    | 'Trial'
    | 'Pay as you go'
    | 'Contract'
    | 'One-off usage'
    | 'Lost';
}

export interface IUser extends Omit<IConnectUser, 'connectId' | 'clientIds'> {
  id: string;
  clients: Record<string, IUserClient>;
}

export interface IConnectUser {
  connectId: string;
  email: string;
  firstName: string;
  lastName: string;
  userType: UserType;
  userRoles: UserRoleType[];
  active: boolean;
  country: string;
  clientIds: string[];
  timezone: {
    abbr: string;
    name: string;
    offset: string;
  };
  lastUpdated: number;
  dateCreated: number;
}
