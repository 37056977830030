export interface IOpportunityOptions {
  callRecording: boolean;
  blind: boolean;
  screenerEnabled?: boolean;
  closedEmailSend: boolean;
  automatedScheduling: boolean;
  conferenceReminderDefaultSettings: IConferenceReminderDefaultSettings;
  failedScreenerEmailSend?: boolean;
  createExpertTranscriptSummary: boolean;
  createSegmentTranscriptSummary: boolean;
  screenshare: boolean; // TODO: Not sure how/if this is used
  callTranscripts: boolean; // TODO: Not sure how/if this is used
  cepLog: boolean; // TODO: Not sure how/if this is used
}

export interface IConferenceReminderDefaultSettings {
  sendEmailBeforeSeconds: number;
  enabled: boolean;
}

export interface IClientContact {
  country: string;
  email: string;
  firstName: string;
  lastName: string;
  primary: boolean;
  timezone: { abbr: string; name: string };
}

export interface IStatPhase {
  activeExperts: number;
  experts: number;
  clientRejected: number;
  adminRejected: number;
  blocked: number;
}

export interface ISearch {
  query: unknown;
  stats: IStats | {};
}

export interface ISearches {
  [key: string]: ISearch;
}

export interface IStats {
  [key: string]: {
    [key: string]: IBaseStats;
  };
}

export interface IBaseStats {
  identified?: IStatPhase;
  firstFollowUp?: IStatPhase;
  secondFollowUp?: IStatPhase;
  outreachComplete?: IStatPhase;
  outreach?: IStatPhase;
  screener?: IStatPhase;
  sentToClient?: IStatPhase;
  accepted?: IStatPhase;
  scheduled?: IStatPhase;
  completed?: IStatPhase;
}

export interface IAggregatedStats {
  geography: string;
  segment: string;
  stats: IBaseStats;
}

export interface IOpportunityAssignee {
  email: string;
  firstName: string;
  lastName: string;
  connectId?: string;
}

export interface ISearchFilterProps {
  sources: string[];
  searches: string[];
  groupIds: string[];
}

export type SalesforceStageName =
  | 'Pre-Bid'
  | 'RFQ'
  | 'Project in progress'
  | 'To be invoiced'
  | 'Closed Lost'
  | 'Closed Won';

export interface IOpportunitySlackChannel {
  name: string;
  id: string;
  type: string;
}

export interface IOpportunityScreenerConfigItem {
  name: string;
  id: string;
}

export interface IOpportunityScreenerConfig {
  mailingListId: string;
  libraryId: string;
  surveys: IOpportunityScreenerConfigItem[];
  messages: IOpportunityScreenerConfigItem[];
}

export interface IOpportunitySegmentBaseStats {
  target: number;
  screening: number;
  inPortal: number;
  scheduled: number;
  completed: number;
  confirmed: number;
}

export interface IOpportunitySegmentStats extends IOpportunitySegmentBaseStats {
  opportunitySegmentId: string;
  geography: string;
  segment: string;
}

export interface IOpportunitySegment {
  opportunitySegmentId: string;
  geography: string;
  segment: string;
  amount: number;
  transcriptionSummaryKey?: string;
}

export interface IOpportunity extends IOpportunityOptions {
  closeDate: string;
  countries: string[];
  startDate: string;
  endDate: string;
  lastUpdated: string;
  opportunityId: string;
  opportunityName: string;
  opportunityOwner: string;
  salesforceId: string;
  status: string;
  clientContactIds: string[];
  expertTargetQuantity?: number;
  searches: ISearches;
  opportunityAssigneeIds: string[];
  anticipatedCallTime?: number;
  clientId: string;
  notes?: string;
  onHold: boolean;
  stageName: SalesforceStageName;
  publicDisplayId: string;

  files: string[];
  defaultConferenceTopic?: string;
  screenerConfig?: IOpportunityScreenerConfig;
  slackChannels: IOpportunitySlackChannel[];

  omnisearchHidden?: boolean;
  omnisearchExpertNotification?: boolean;

  opportunitySegmentStats: IOpportunitySegmentStats[];
  opportunitySegmentStatsTotal: IOpportunitySegmentBaseStats;
  opportunitySegments: IOpportunitySegment[];

  screenerComparisonEnabled?: boolean;
}
