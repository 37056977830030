<div fxLayout="row" fxLayoutAlign="space-between">
  <p data-test-id="acceptance-withdrawal-blocked-dialog-body">
    Approval cannot be withdrawn for experts with scheduled calls. To cancel this call, please contact your Techspert
    project management team.
  </p>

  <button mat-icon-button mat-dialog-close data-test-id="select-availability-cancel-button">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<div fxLayout="column">
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center" fxFill>
    <button mat-flat-button mat-dialog-close color="primary" fxFlex="50%"
      data-test-id="acceptance-withdrawal-blocked-dialog-close-button">Close Window</button>
  </div>
</div>