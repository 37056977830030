import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IGlobalExpert } from '../../../models/omnisearch.models';
import { OmnisearchService } from '../../../services/omnisearch.service';

@Component({
  selector: 'app-global-result',
  templateUrl: './global-result.component.html',
  styleUrls: ['../omnisearch-result.component.scss'],
})
export class GlobalResultComponent {
  @Input() omnisearchResponse: IGlobalExpert;
  @Input() engagementRequested: boolean;
  @Output() tabChange = new EventEmitter<number>();
  @Output() requestExpertEngagement = new EventEmitter<IGlobalExpert>();
  @Output() requestExpertInformation = new EventEmitter<IGlobalExpert>();

  get searchTerm(): string {
    return this.omnisearchService.searchForm.get('search').value;
  }

  constructor(private omnisearchService: OmnisearchService) {}

  requestEngagement(): void {
    this.requestExpertEngagement.emit(this.omnisearchResponse);
  }

  requestInformation(): void {
    this.requestExpertInformation.emit(this.omnisearchResponse);
  }
}
