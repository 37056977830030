import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { CognitoAuthService } from '@techspert-io/auth';
import * as ct from 'countries-and-timezones';
import * as moment from 'moment';
import { merge, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { IExpertAvailabilitiesActionTimeSlot } from '../../../../../../shared/models/expert-availability-actions.models';
import { IExpert } from '../../../../../../shared/models/expert.interface';
import { TIME_ZONE } from '../../../../../../shared/pipes/availability-date.pipe';

export interface IRequestTimeChangeCloseData {
  slots: IExpertAvailabilitiesActionTimeSlot[];
  notes: string;
  clientTimezone: string;
}

export interface IRequestTimeChangeData {
  expert: IExpert;
  /* minutes */
  slotLength: number;
  /* minutes */
  slotGap: number;
  error?: string;
}

interface ITimeSlot {
  client: ISlot;
  expert: ISlot;
  raw: IExpertAvailabilitiesActionTimeSlot;
}

interface ISlot {
  label?: string;
  date: string;
  start: string;
  end: string;
}

@Component({
  selector: 'app-request-time-change',
  templateUrl: './request-time-change.component.html',
  styleUrls: ['./request-time-change.component.scss'],
})
export class RequestTimeChangeComponent implements OnInit {
  private destroy$ = new Subject();

  @ViewChild(MatTable) table: MatTable<ITimeSlot>;

  displayedColumns: string[] = ['date', 'startTime', 'endTime', 'opts'];
  tzOptions = Object.values(ct.getAllTimezones({ deprecated: false }))
    .filter((tz) => tz.name !== 'Factory')
    .map((tzObj) => tzObj.name);
  minDate = moment().format('YYYY-MM-DD');
  slots: ITimeSlot[] = [];

  datesForm = new FormGroup({
    date: new FormControl<string>(null, [
      Validators.required,
      this.validateNotBeforeToday(),
    ]),
    startTime: new FormControl<string>(null, Validators.required),
    endTime: new FormControl<string>(null, Validators.required),
  });

  availabiltyForm = new FormGroup({
    slots: this.datesForm,
    notes: new FormControl<string>(null, Validators.required),
    timezone: new FormControl<string>(null),
  });

  get error(): string {
    return this.data.error;
  }

  get expertName(): string {
    return this.data.expert.firstName;
  }

  get expertTimezone(): string {
    return this.data.expert.timezoneName;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IRequestTimeChangeData,
    private dialogRef: MatDialogRef<
      RequestTimeChangeComponent,
      IRequestTimeChangeCloseData
    >,
    private auth: CognitoAuthService,
    @Inject(TIME_ZONE) private userTz: string
  ) {}

  ngOnInit(): void {
    const today = moment().set({ hour: 12, minutes: 0 });
    this.resestDatePickers(today);
    this.availabiltyForm.controls.timezone.setValue(
      this.auth.loggedInUser?.timezone.name || this.userTz
    );

    const timezoneChanges$ =
      this.availabiltyForm.controls.timezone.valueChanges.pipe(
        tap((tz) => this.updateTimeSlots(tz))
      );

    const datesChanges$ = this.datesForm.valueChanges.pipe(
      filter(() => this.datesForm.dirty),
      debounceTime(500),
      tap((values) => this.updateEndDate(values))
    );

    merge(timezoneChanges$, datesChanges$)
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  submitDates(): void {
    if (this.datesForm.valid) {
      const formData = this.datesForm.value;

      this.slots = this.slots
        .concat(
          this.buildSlot(
            formData.date,
            formData.startTime,
            formData.endTime,
            this.availabiltyForm.value.timezone
          )
        )
        .sort((a, b) => a.raw.start.localeCompare(b.raw.end));
    }

    this.table.renderRows();
  }

  remove(idx: number): void {
    this.slots = this.slots.filter((_, i) => i !== idx);
  }

  sendRequest(): void {
    this.availabiltyForm.markAllAsTouched();
    if (this.availabiltyForm.valid) {
      const slots = this.slots
        .flatMap((d) => this.formatAvailabilitySlots(d.raw.start, d.raw.end))
        .filter(
          (slot, i, arr) => arr.findIndex((s) => s.start === slot.start) === i
        );
      this.dialogRef.close({
        slots,
        clientTimezone: this.availabiltyForm.value.timezone,
        notes: this.availabiltyForm.value.notes,
      });
    }
  }

  private updateTimeSlots(tz: string): void {
    this.slots = this.slots.map((s) => {
      const date = moment(s.client.date).format('YYYY-MM-DD');
      return this.buildSlot(date, s.client.start, s.client.end, tz);
    });
  }

  private resestDatePickers(startDateIn: moment.Moment): void {
    const date = startDateIn.format('YYYY-MM-DD');
    const startTime = startDateIn.format('HH:mm');

    const endDateEx = startDateIn.clone().add(60, 'm');
    const endTime = endDateEx.format('HH:mm');

    this.datesForm.patchValue({
      date,
      startTime,
      endTime,
    });
  }

  private updateEndDate(formData: FormGroup['value']): void {
    const { start, end } = this.buildDates(
      formData.date,
      formData.startTime,
      formData.endTime,
      this.availabiltyForm.value.timezone
    );
    if (moment(end).isSameOrBefore(start)) {
      const endDate = start.add(60, 'm');
      this.datesForm.patchValue(
        {
          endTime: endDate.format('HH:mm'),
        },
        { emitEvent: false }
      );
    }
  }

  private buildSlot(
    date: string,
    startTime: string,
    endTime: string,
    timezone: string
  ) {
    const clientTimes = this.buildDates(date, startTime, endTime, timezone);

    const expertStart = clientTimes.start
      .clone()
      .tz(this.expertTimezone || 'UTC');
    const expertEnd = clientTimes.end.clone().tz(this.expertTimezone || 'UTC');

    const offset =
      (expertStart.utcOffset() - clientTimes.start.utcOffset()) / 60;

    const timeOffset = offset > 0 ? `+${offset}` : `${offset}`;

    return {
      client: {
        date: clientTimes.start.format(),
        start: clientTimes.start.format('HH:mm'),
        end: clientTimes.end.format('HH:mm'),
      },
      expert: {
        label: `${timeOffset}hrs (${this.expertTimezone || 'UTC'})`,
        date: expertStart.format(),
        start: `${timeOffset}hrs ${expertStart.format('HH:mm')}`,
        end: `${timeOffset}hrs ${expertEnd.format('HH:mm')}`,
      },
      raw: {
        start: clientTimes.start.toISOString(),
        end: clientTimes.end.toISOString(),
      },
    };
  }

  private buildDates(
    date: string,
    startTime: string,
    endTime: string,
    tz: string
  ): { start: moment.Moment; end: moment.Moment } {
    const start = moment.tz(`${date} ${startTime}`, tz);
    const end = moment.tz(`${date} ${endTime}`, tz);
    return { start, end };
  }

  private formatAvailabilitySlots(
    startDate: string,
    endDate: string
  ): IExpertAvailabilitiesActionTimeSlot[] {
    const slotMins = this.data.slotLength;
    const slotGap = this.data.slotGap;
    const currentDate = moment(startDate);
    const lastDate = moment(endDate);

    const sTime = moment(currentDate, 'HH:mm');
    const slotsDiff = moment(lastDate, 'HH:mm')
      .add(-slotMins, 'minutes')
      .diff(sTime, 'minutes');

    const timeSlots = [
      ...new Array(Math.floor(slotsDiff / slotGap) + 1).keys(),
    ];

    return [...new Array(lastDate.diff(currentDate, 'days') + 1).keys()]
      .map((i) =>
        currentDate
          .clone()
          .add(i, 'days')
          .hour(sTime.hour())
          .minute(sTime.minute())
      )
      .flatMap((d) =>
        timeSlots.map((t) => d.clone().add(t * slotGap, 'minutes'))
      )
      .map((date) => ({
        start: date.toISOString(),
        end: date.add(slotMins, 'minutes').toISOString(),
      }));
  }

  private validateNotBeforeToday(): ValidatorFn {
    return (control: FormControl<string>): ValidationErrors => {
      if (moment(control.value).isBefore(moment(), 'day')) {
        return {
          validateNotBeforeToday: true,
        };
      }

      return null;
    };
  }
}
