export type FileType =
  | 'screenerResponse'
  | 'zoomRecording'
  | 'zoomTranscript'
  | 'manual';

export interface IExpertFile {
  fileName: string;
  fileExtension: string;
  fileKey: string;
  pendingApproval: boolean;
  type: FileType;
}
