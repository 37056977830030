import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';

const pipes = [SafeHtmlPipe];

@NgModule({
  imports: [FlexLayoutModule],
  declarations: [...pipes],
  exports: [...pipes],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
  ],
})
export class PatternsModule {}
