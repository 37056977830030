import {
  Directive,
  HostListener,
  Inject,
  InjectionToken,
  Input,
} from '@angular/core';
import * as Excel from 'exceljs';
import * as moment from 'moment';
import { IExpert } from '../../../shared/models/expert.interface';
import { TIME_ZONE } from '../../../shared/pipes/availability-date.pipe';
import {
  IExpertPhases,
  PortalPhases,
} from '../../../shared/state/expert.service';
import { AuthDialogInceptorService } from '../services/auth-dialog-inceptor.service';
import { WorksheetService } from '../services/worksheet.service';

export const WORKBOOK = new InjectionToken<Excel.Workbook>('WORKBOOK');

@Directive({
  selector: 'button[appExperts]',
  providers: [
    {
      provide: WORKBOOK,
      useValue: new Excel.Workbook(),
    },
  ],
})
export class ExpertSummaryDownloadDirective {
  @Input('appExperts') experts: IExpertPhases;
  @Input() opportunityName: string;
  @Input() isFileDownloadAuthorised: boolean;

  constructor(
    private authDialogInceptorService: AuthDialogInceptorService,
    private worksheetService: WorksheetService,
    @Inject(TIME_ZONE) private timezone: string,
    @Inject(WORKBOOK) private workbook: Excel.Workbook
  ) {}

  @HostListener('click')
  downloadProgressCsv(): void {
    this.authDialogInceptorService.authIntercept(
      this.isFileDownloadAuthorised,
      () => this.downloadProgressCsvImpl()
    );
  }

  private downloadProgressCsvImpl(): void {
    const worksheetExists = this.workbook.getWorksheet(this.opportunityName);

    if (worksheetExists) {
      this.workbook.removeWorksheet(this.opportunityName);
    }

    const worksheet = this.workbook.addWorksheet(this.opportunityName, {
      properties: {
        defaultColWidth: 23,
        defaultRowHeight: 22,
      },
    });

    this.formatWorksheet(worksheet, this.experts);

    this.worksheetService.save(this.workbook, 'export');
  }

  private formatWorksheet(
    worksheet: Excel.Worksheet,
    experts: Record<PortalPhases, IExpert[]>
  ): void {
    worksheet.columns = [
      { header: 'Expert initials', key: 'expertInitials' },
      { header: 'Geography', key: 'geography' },
      { header: 'Profile Type', key: 'profileType' },
      { header: 'Bio', key: 'bio' },
      { header: 'Screener', key: 'screener' },
      { header: 'Phase', key: 'phase' },
      { header: 'Call time', key: 'callTime' },
      { header: 'Comments', key: 'comments' },
      { header: 'Last updated', key: 'lastUpdated' },
    ];

    Object.entries(experts)
      .map(([k, v]) => v.map((e) => ({ ...e, k })))
      .flat()
      .map((e: IExpert & { k: PortalPhases }) =>
        worksheet.addRow({
          expertInitials: `${e.firstName[0]} ${e.lastName[0]}`,
          geography: e.geographicTarget,
          profileType: e.profileType,
          bio: this.stripHTML(e.bio || ''),
          screener: this.stripHTML(e.screenerResponse || ''),
          phase: this.convertPortalPhase(e.k),
          callTime: moment(e.callTime)
            .tz(this.timezone)
            .format('DD-MM-YYYY HH:mm'),
          comments: e.clientNotes,
          lastUpdated: moment(e.lastUpdated * 1000)
            .tz(this.timezone)
            .format('DD-MM-YYYY HH:mm'),
        })
      );
  }

  private convertPortalPhase(portalPhase: PortalPhases): string {
    switch (portalPhase) {
      case 'newExperts':
        return 'New profile';
      case 'acceptedExperts':
        return 'Upcoming connection';
      case 'completedConnections':
        return 'Completed connection';
      case 'declinedExperts':
        return 'Declined expert';
      default:
        return 'New profile';
    }
  }

  private stripHTML(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }
}
