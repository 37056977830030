import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  ExpertReadyToAcceptCodeMap,
  IExpertReadyToAcceptReturn,
} from '@techspert-io/experts';
import { TIME_ZONE_ABBR } from '../../../../../../shared/pipes/availability-date.pipe';

export interface ISelectAvailabilityDialogData {
  availability: string;
  readyToAccept: IExpertReadyToAcceptReturn;
}

@Component({
  selector: 'app-select-availability-dialog',
  templateUrl: './select-availability-dialog.component.html',
  styleUrls: ['./select-availability-dialog.component.scss'],
})
export class SelectAvailabilityDialogComponent implements OnInit {
  errorMessage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: ISelectAvailabilityDialogData,
    @Inject(TIME_ZONE_ABBR) public timezoneAbbr: string,
    public dialogRef: MatDialogRef<SelectAvailabilityDialogComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (!this.data.readyToAccept.isReady) {
      switch (this.data.readyToAccept.code) {
        case ExpertReadyToAcceptCodeMap.ClientRejected:
          this.errorMessage =
            'It looks like this expert has been rejected - please refresh your page & un-reject the expert to accept a time';
          break;
        case ExpertReadyToAcceptCodeMap.Accepted:
          this.errorMessage =
            'It looks like a time has already been scheduled for this expert - please refresh your page to view the selected time';
          break;
        case ExpertReadyToAcceptCodeMap.Hidden:
          this.errorMessage =
            "We're sorry this expert is no longer available - please contact your Project Manager for more information";
          break;
        default:
          this.errorMessage =
            'Expert data out of date - please refresh the page and try again';
      }
    }
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  reload(): void {
    location.reload();
  }
}
