import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { combineLatest, fromEvent, merge, Subject } from 'rxjs';
import {
  filter,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CognitoAuthService } from './features/auth/services/cognito-auth.service';
import { OmnisearchService } from './features/omnisearch/services/omnisearch.service';

export const GA_MEASUREMENT_ID = new InjectionToken('GA_MEASUREMENT_ID');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  showClose = true;

  omnisearchData$ = combineLatest([
    this.omnisearchService.omnisearchResults$,
    this.omnisearchService.recommendedExperts$,
    this.omnisearchService.queryType$,
    this.omnisearchService.viewingOmnisearchProjectName$,
    this.omnisearchService.omnisearchFocused$,
  ]).pipe(
    map(
      ([
        omnisearchResults,
        recommendedExperts,
        queryType,
        omnisearchProjectName,
        omnisearchFocused,
      ]) => ({
        omnisearchResults,
        recommendedExperts,
        queryType,
        omnisearchProjectName,
        omnisearchFocused,
      })
    )
  );

  constructor(
    private router: Router,
    private cognitoAuthService: CognitoAuthService,
    private ccService: NgcCookieConsentService,
    private gaService: GoogleAnalyticsService,
    private omnisearchService: OmnisearchService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(GA_MEASUREMENT_ID) private gaMeasurementId: string
  ) {}

  ngOnInit(): void {
    this.ccService.statusChange$
      .pipe(takeUntil(this.destroy$), startWith(0))
      .subscribe(() => this.handleCookieConsent());

    this.cognitoAuthService.authError$
      .pipe(takeUntil(this.destroy$))
      .subscribe((err) =>
        this.router.navigate(['not-authorised'], {
          queryParams: { message: err.message },
        })
      );

    fromEvent(window, 'unload')
      .pipe(first(() => this.router.url.startsWith('/connect')))
      .subscribe(() => this.cognitoAuthService.setRedirectUrl(this.router.url));

    const routerNavigation$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e) => e as NavigationEnd)
    );

    routerNavigation$.subscribe((e) => {
      if (!e.url.startsWith('/connect')) {
        this.omnisearchService.setViewedProject('');
      }

      const visitedRoot = e.url.startsWith('/search');
      if (visitedRoot) {
        this.omnisearchService.clearQuery();
        this.omnisearchService.clearRecommendedExperts();
        this.omnisearchService.setFocused(true);
      } else {
        this.omnisearchService.setFocused(false);
      }

      this.showClose = !visitedRoot;
    });

    combineLatest([
      routerNavigation$.pipe(first()),
      this.cognitoAuthService
        .initAuth()
        .pipe(
          switchMap(() =>
            combineLatest([
              this.cognitoAuthService.isClientFeatureEnabled(
                'omnisearchEnabled'
              ),
              this.cognitoAuthService.isClientFeatureEnabled(
                'omnisearchGlobalEnabled'
              ),
            ])
          )
        ),
    ])
      .pipe(takeUntil(merge(this.cognitoAuthService.authError$, this.destroy$)))
      .subscribe(([e, [omnisearchEnabled, omnisearchGlobalEnabled]]) => {
        this.omnisearchService.setEnabled(omnisearchEnabled);
        this.omnisearchService.setGlobalEnabled(omnisearchGlobalEnabled);

        const redirectUrl = this.cognitoAuthService.getRedirectUrl();

        const [, baseURL] = e.url.split('/');

        if (
          redirectUrl &&
          ![
            '',
            'search',
            'not-found',
            'call-complete',
            'screener-comparison',
          ].includes(baseURL) &&
          !e.url.startsWith('/connect')
        ) {
          this.router.navigate([redirectUrl], {
            replaceUrl: true,
          });
        } else if (e.url.startsWith('/secure')) {
          this.router.navigate(['/']);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleCookieConsent(): void {
    const consent = this.ccService.hasConsented();

    this.gaService.gtag('consent', 'default', {
      analytics_storage: consent ? 'granted' : 'denied',
    });

    this.document.defaultView[`ga-disable-${this.gaMeasurementId}`] = !consent;

    if (consent && environment.production) {
      this.loadHotjarScript();
    }
  }

  private loadHotjarScript(): void {
    const node = document.createElement('script');
    node.innerHTML = `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:2149579,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
