import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard as Auth0Guard } from '@auth0/auth0-angular';
import { AuthGuard, SearchGuard } from '@techspert-io/auth';
import { OmnisearchPageComponent } from '@techspert-io/omnisearch';
import { UserRoleGuard } from './features/auth/guards/user-role.guard';
import { PrivateComponent } from './private/private.component';
import { OpportunityComponent } from './public/opportunity/opportunity.component';
import { LandingPageComponent } from './shared/components/landing-page/landing-page.component';
import { NotAuthorisedComponent } from './shared/components/not-authorised/not-authorised.component';

const routes: Routes = [
  {
    path: 'secure',
    component: PrivateComponent,
    canActivate: [Auth0Guard],
  },
  {
    path: 'connect/:id/:projectCode',
    component: OpportunityComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'connect/:id',
    component: OpportunityComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    component: OmnisearchPageComponent,
    canActivate: [SearchGuard],
  },
  {
    path: 'call-complete',
    loadChildren: () =>
      import('./features/call-actions/call-actions.module').then(
        (m) => m.CallActionsModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [Auth0Guard, UserRoleGuard],
  },
  { path: 'not-authorised', component: NotAuthorisedComponent },
  {
    path: 'not-found',
    component: LandingPageComponent,
  },
  {
    path: 'screener-comparison',
    loadChildren: () =>
      import('./features/screener-comparison/screener-comparison.module').then(
        (m) => m.ScreenerComparisonModule
      ),
  },
  { path: '**', redirectTo: 'search' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
