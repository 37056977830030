import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-omnisearch-enquiry-result',
  template: `<div class="enquiry-container">
    <div class="header" fxLayoutAlign="center center" fxFill>
      <h1>{{ requestStatus === 'success' ? 'Success!' : 'Fail' }}</h1>
    </div>

    <ng-container *ngIf="requestStatus === 'success'">
      <h1>Thank you</h1>
      <h4>Your enquiry has been received and Simon will be right in touch</h4>
    </ng-container>

    <h1 *ngIf="requestStatus === 'fail'">
      An error occurred while handling this enquiry. Our tech team has been
      notified!
    </h1>

    <button mat-flat-button color="primary" fxFill mat-dialog-close>
      Close window
    </button>
  </div>`,
  styles: [
    `
      .enquiry-container {
        text-align: center;
      }

      .header {
        background-color: #ffe1e7;
        min-height: 20vh;
        border-radius: 24px;
      }

      h1 {
        color: #1e2b4e;
      }

      button {
        padding: 8px 0;
      }
    `,
  ],
})
export class OmnisearchEnquiryResultComponent {
  @Input() requestStatus: 'success' | 'fail';
}
