import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CognitoAuthService } from '@techspert-io/auth';
import { OpportunityNavigationService } from '@techspert-io/navigation';
import { IOpportunity } from '@techspert-io/opportunities';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, EMPTY, Observable, Subject } from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { OmnisearchService } from '../../features/omnisearch/services/omnisearch.service';
import { IExpert } from '../../shared/models/expert.interface';
import {
  ExpertStoreService,
  IExpertPhases,
} from '../../shared/state/expert.service';
import { OpportunityStoreService } from '../../shared/state/opportunity.service';
import { AuthDialogInceptorService } from './services/auth-dialog-inceptor.service';

@Component({
  selector: 'app-opportunity',
  templateUrl: './opportunity.component.html',
  styleUrls: ['./opportunity.component.scss'],
})
export class OpportunityComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  private tableRendered$ = new Subject();

  newExpertCount: number;
  showDescription = true;
  searchTerm: string;
  selectedIndex = 0;
  showTrackingTable: boolean;
  isFileDownloadAuthorised = false;
  isClientUser = false;
  showOpp = true;
  forceLogin = false;

  experts$ = this.expertStore.experts$;
  expertLoadingIds$ = this.expertStore.expertLoadingIds$;

  opportunity$: Observable<IOpportunity> = EMPTY;
  isExpertApprovalRequired$: Observable<{ value: boolean }> = EMPTY;

  @ViewChild('tab-group') set table(_: HTMLElement) {
    this.tableRendered$.next(true);
  }

  constructor(
    private opportunityStore: OpportunityStoreService,
    private expertStore: ExpertStoreService,
    private activatedRoute: ActivatedRoute,
    private cognitoAuthService: CognitoAuthService,
    private router: Router,
    private location: Location,
    private titleService: Title,
    private omnisearchService: OmnisearchService,
    private opportunityNavigationState: OpportunityNavigationService,
    private gaService: GoogleAnalyticsService,
    private toastService: ToastrService,
    private authDialogInceptorService: AuthDialogInceptorService
  ) {}

  ngOnInit(): void {
    this.omnisearchService.omnisearchFocused$
      .pipe(tap((focused) => (this.showOpp = !focused)))
      .subscribe();

    const opp$ = this.activatedRoute.paramMap.pipe(
      map((params) => params.get('id')),
      tap(() => this.toastService.clear()),
      tap(() => this.omnisearchService.clearRecommendedExperts()),
      tap(() => this.omnisearchService.setFocused(false)),
      switchMap((oppId) => this.opportunityStore.getOpportunity(oppId)),
      catchError((error) => {
        switch (error?.status) {
          case 401:
            this.forceLogin = true;
            break;
          case 403:
            this.router.navigate(['/not-authorised']);
            break;
          case 404:
          default:
            this.router.navigate(['not-found']);
        }

        return EMPTY;
      }),
      shareReplay(1)
    );

    opp$
      .pipe(
        filter(
          (opp) =>
            this.cognitoAuthService.loggedInUser?.clients[opp.clientId] &&
            this.cognitoAuthService.loggedInUser?.clients[opp.clientId].features
              .omnisearchEnabled
        ),
        filter((opp) => opp.stageName === 'Project in progress'),
        switchMap((opp) =>
          this.omnisearchService.getRelatedExperts(opp.opportunityId)
        )
      )
      .subscribe();

    this.opportunity$ = opp$.pipe(
      tap((opportunity) => this.setupOpportunity(opportunity))
    );

    this.isExpertApprovalRequired$ = this.cognitoAuthService
      .isClientFeatureEnabled('expertApprovalRequired')
      .pipe(map((value) => ({ value })));

    combineLatest([
      this.activatedRoute.queryParams,
      this.expertStore.experts$,
      this.opportunityNavigationState.state$,
    ])
      .pipe(
        take(1),
        tap(([queryParams, experts]) =>
          this.setupRouting(queryParams.expertId, experts)
        )
      )
      .subscribe();

    combineLatest([this.activatedRoute.queryParams, this.tableRendered$])
      .pipe(filter(([params]) => params.expertId))
      .subscribe(([params]) =>
        document.getElementById(params.expertId)?.scrollIntoView()
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  selectedTabChange(tabId: number): void {
    this.opportunityNavigationState.setTabState(tabId);
  }

  adjustTabIndex(tabId: number): void {
    this.selectedIndex = tabId;
  }

  toggleShowDescription(): void {
    this.showDescription = !this.showDescription;
  }

  openOmnisearchFromProjectProgressPage(): void {
    this.omnisearchService.setFocused(true);

    if (this.cognitoAuthService.loggedInUser) {
      this.gaService.gtag('event', 'click', {
        event_category: 'new_project_omnisearch_notification',
        dimension1: this.cognitoAuthService.loggedInUser.id,
      });
    }
  }

  navigateScreener(opportunityId: string): void {
    this.authDialogInceptorService.authIntercept(
      this.isFileDownloadAuthorised,
      () => this.router.navigate(['/screener-comparison', opportunityId])
    );
  }

  private setupOpportunity(opportunity: IOpportunity): void {
    this.appendOpportunityCodeToUrl(opportunity);

    this.omnisearchService.setViewedProject(opportunity.opportunityName);

    this.showTrackingTable = this.isTrackable(opportunity);

    this.titleService.setTitle(opportunity.opportunityName);

    const userClient =
      this.cognitoAuthService.loggedInUser?.clients[opportunity.clientId];

    this.isClientUser =
      !!userClient || this.cognitoAuthService.loggedInUser?.userType === 'PM';

    this.isFileDownloadAuthorised = this.isClientUser;
    this.expertStore.setOpportunityId(opportunity.opportunityId);
  }

  private setupRouting(expertId?: string, experts?: IExpertPhases): void {
    const tabIndex = Object.values(experts || {})
      .map((v: IExpert[], i) => v.find((e) => e.expertId === expertId) && i + 1)
      .find(Boolean);

    if (tabIndex) {
      this.opportunityNavigationState.setNavigationState({
        [expertId]: true,
      });
    }

    this.selectedIndex =
      this.selectedIndex ||
      tabIndex ||
      this.opportunityNavigationState.selectedTab ||
      0;

    this.opportunityNavigationState.setTabState(this.selectedIndex);
  }

  private isTrackable(opp: IOpportunity): boolean {
    if (opp.opportunitySegments?.length) {
      return !opp.startDate || new Date(opp.startDate).getFullYear() > 2020;
    }
    return false;
  }

  private appendOpportunityCodeToUrl(opportunity: IOpportunity): void {
    this.location.replaceState(
      `/connect/${opportunity.publicDisplayId}/${opportunity.opportunityName}`
    );
  }
}
