import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CognitoAuthService } from '../services/cognito-auth.service';

@Injectable({
  providedIn: 'root',
})
export class SearchGuard implements CanActivate {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.cognitoAuthService.loggedInConnect$.pipe(
      tap((loggedIn) => !loggedIn && this.router.navigate(['secure'])),
      switchMap((loggedIn) =>
        loggedIn
          ? this.cognitoAuthService.isClientFeatureEnabled('omnisearchEnabled')
          : of(false)
      ),
      tap(
        (valid) =>
          !valid &&
          this.router.navigate([
            this.cognitoAuthService.getRedirectUrl() || 'not-found',
          ])
      )
    );
  }
}
