export interface IDataAccessLogs {
  dateCreated: number;
  lastUpdated: number;
  createdBy?: string;
  lastUpdatedBy?: string;
}

export interface ISharedExpertEmailBase {
  sharedExpertActionId: string;
  expertId: string;
  userId: string;
  recipients: string[];
  notes?: string;
}

export interface ISharedExpertEmail
  extends ISharedExpertEmailBase,
    IDataAccessLogs {}
