import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthDialogComponent } from '../components/auth-dialog/auth-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AuthDialogInceptorService {
  constructor(private dialog: MatDialog) {}

  authIntercept(
    isAuthorised: boolean,
    func: () => void = (): void => void 0
  ): void {
    if (isAuthorised) {
      return func();
    }

    this.dialog
      .open(AuthDialogComponent, {
        width: '400px',
      })
      .afterClosed()
      .subscribe();
  }
}
