import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoAuthService } from '@techspert-io/auth';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import {
  IOmnisearchExpert,
  IRedisExpertFields,
  OmnisearchQueryResponse,
} from '../models/omnisearch.models';

interface IRedisOpportunityFields {
  name?: boolean;
}

export interface IContractRequest {
  fullName: string;
  emailAddress: string;
  phoneNumber: string;
  accountName: string;
  additionalComments?: string;
}

export enum QueryType {
  query = 'query',
  recommended = 'recommended',
}

@Injectable()
export class OmnisearchService {
  private readonly baseUrl = '/omnisearch/portal';

  private queryInner$ = new BehaviorSubject('');

  private recommendedExpertsInner$ = new BehaviorSubject<IOmnisearchExpert[]>(
    []
  );

  private expertFields$ = new BehaviorSubject<IRedisExpertFields>({
    expertise: true,
    name: true,
    bio: true,
    geographicTarget: true,
    portalAffiliations: true,
    profileType: true,
  });
  private opportunityFields$ = new BehaviorSubject<IRedisOpportunityFields>({
    name: true,
  });
  private showOmnisearch$ = new BehaviorSubject<boolean>(false);
  private omniseachEnabledInner$ = new BehaviorSubject<boolean>(false);
  private omniseachGlobalEnabledInner$ = new BehaviorSubject<boolean>(false);
  private omniseachSalesCarrotEnabledInner$ = new BehaviorSubject<boolean>(
    false
  );

  viewingOmnisearchProjectName$ = new BehaviorSubject<string>('');
  omnisearchFocused$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);

  omnisearchFilters$ = combineLatest([
    this.expertFields$,
    this.opportunityFields$,
  ]);

  recommendedExperts$ = this.recommendedExpertsInner$;

  omnisearchEnabled$ = this.omniseachEnabledInner$.asObservable();
  omniseachGlobalEnabled$ = this.omniseachGlobalEnabledInner$.asObservable();
  omniseachSalesCarrotEnabled$ =
    this.omniseachSalesCarrotEnabledInner$.asObservable();

  omnisearchResults$ = combineLatest([
    this.queryInner$,
    this.omnisearchFilters$,
    this.omniseachGlobalEnabled$,
  ]).pipe(
    switchMap(([query, [expertFields, opportunityFields], isGlobal]) =>
      this.query(
        query,
        expertFields,
        opportunityFields,
        isGlobal ? 'global' : 'clientOnly'
      )
    )
  );

  queryType$ = combineLatest([this.recommendedExperts$, this.queryInner$]).pipe(
    map(([recommendedExperts, query]) => {
      if (query.length > 1) {
        return QueryType.query;
      }

      if (recommendedExperts.length) {
        return QueryType.recommended;
      }
    })
  );

  searchForm = new FormGroup({
    search: new FormControl(null, [
      Validators.minLength(1),
      Validators.required,
    ]),
  });

  constructor(
    private http: HttpClient,
    private cognitoAuthService: CognitoAuthService,
    private gaService: GoogleAnalyticsService,
    private toastService: ToastrService
  ) {}

  setExpertFields(fields: IRedisExpertFields): void {
    this.expertFields$.next(fields);
  }

  setOpportunityFields(fields: IRedisOpportunityFields): void {
    this.opportunityFields$.next(fields);
  }

  setQuery(query: string): void {
    this.queryInner$.next(query);
  }

  clearQuery(): void {
    this.toastService.clear();
    this.searchForm.setValue({
      search: '',
    });
    this.queryInner$.next('');
  }

  getQuery(): BehaviorSubject<string> {
    return this.queryInner$;
  }

  setShowOmnisearch(show: boolean): void {
    this.showOmnisearch$.next(show);
  }

  getShowOmnisearch(): Observable<boolean> {
    return this.showOmnisearch$;
  }

  setFocused(focused: boolean): void {
    this.omnisearchFocused$.next(focused);
  }

  setViewedProject(projectName: string): void {
    this.viewingOmnisearchProjectName$.next(projectName);
  }

  setEnabled(enabled: boolean): void {
    this.omniseachEnabledInner$.next(enabled);
  }

  setGlobalEnabled(toggleEngabled: boolean): void {
    this.omniseachGlobalEnabledInner$.next(toggleEngabled);
  }

  setOmniSalesCarrot(enabled: boolean): void {
    this.omniseachSalesCarrotEnabledInner$.next(enabled);
  }

  clearRecommendedExperts(): void {
    this.recommendedExpertsInner$.next([]);
  }

  getRelatedExperts(oppId: string): Observable<void> {
    return this.http
      .get<IOmnisearchExpert[]>(`${this.baseUrl}/${oppId}/related_experts`)
      .pipe(
        tap((e) => this.recommendedExpertsInner$.next(e)),
        filter((experts) => !!experts.length),
        switchMap((experts) =>
          this.toastService
            .success(
              `Click here to view your ${experts.length} ${
                experts.length === 1 ? 'profile' : 'profiles'
              }`,
              'Your project has been matched with similar experts',
              { disableTimeOut: true, closeButton: true }
            )
            .onTap.pipe(
              take(1),
              tap(() => {
                this.clearQuery();
                this.setFocused(true);
                this.gaService.gtag('event', 'click', {
                  event_category: 'ongoing_project_omnisearch_notification',
                  dimension1: this.cognitoAuthService.loggedInUser.id,
                });
              })
            )
        )
      );
  }

  favouriteExpert(
    expertId: string,
    favouriteStatus: boolean
  ): Observable<IOmnisearchExpert> {
    return this.http.post<IOmnisearchExpert>(`/data/portal/experts/update`, [
      {
        expertId,
        update: { favourited: !favouriteStatus },
      },
    ]);
  }

  enquireContract(enquirePayload: IContractRequest): Observable<void> {
    return this.http.post<void>(
      '/client-portal/clients/omni-request',
      enquirePayload
    );
  }

  private query(
    query: string,
    expertFields: IRedisExpertFields,
    oppFields: IRedisOpportunityFields,
    queryType: 'global' | 'clientOnly'
  ): Observable<OmnisearchQueryResponse[]> {
    if (query.length < 2) {
      return of([]);
    }

    return queryType === 'global'
      ? this.constructQuery(query, expertFields, 'search-global', {})
      : this.constructQuery(query, expertFields, 'search', oppFields);
  }

  private constructQuery(
    query: string,
    expertFields: IRedisExpertFields,
    endpointUrl: string,
    oppFields: IRedisOpportunityFields
  ) {
    this.loading$.next(true);
    const params = this.getQueryParams(query, expertFields, oppFields);

    return this.http
      .get<OmnisearchQueryResponse[]>(`${this.baseUrl}/${endpointUrl}`, {
        params,
      })
      .pipe(
        tap(() => this.loading$.next(false)),
        tap((res) =>
          this.gaService.gtag('event', 'search', {
            event_category: 'omnisearch',
            event_label: query,
            value: res.length,
            dimension1: this.cognitoAuthService.loggedInUser.id,
          })
        ),
        catchError(() => of([]))
      );
  }

  private getQueryParams(
    query: string,
    expertFields: IRedisExpertFields,
    oppFields: IRedisOpportunityFields
  ): HttpParams {
    const enabledExpertFields = expertFields
      ? this.getValidFields(expertFields)
      : [];
    const enabledOpportunityFields = oppFields
      ? this.getValidFields(oppFields)
      : [];

    return [
      expertFields ? `portalExperts=${enabledExpertFields.join(',')}` : '',
      oppFields ? `opportunities=${enabledOpportunityFields.join(',')}` : '',
    ]
      .filter(Boolean)
      .reduce((acc, curr) => {
        const [entity, fields] = curr.split('=');
        return acc.append(entity, fields);
      }, new HttpParams().set('query', query).set('clientId', Object.keys(this.cognitoAuthService.loggedInUser.clients).find(Boolean)));
  }

  private getValidFields(
    expertFields: IRedisExpertFields | IRedisOpportunityFields
  ): string[] {
    return Object.entries(expertFields)
      .filter(([, v]) => v)
      .map(([k]) => k);
  }
}
