<div class="info" fxLayout="row" fxFlex="grow" fxLayoutGap="12px" fxLayoutAlign="start top">
    <mat-icon class="icon" matTooltip="Project">
        insert_chart_outlined
    </mat-icon>
    <div class="title" [innerHTML]="omnisearchResponse.name | searchHighlight: searchTerm | safeHtml"
        data-test-id="omnisearch-result-opportunity-name">
    </div>
</div>
<div fxLayout="column" fxFlex.gt-xs="20" fxLayout.xs="row" fxLayoutGap="4px" fxLayoutAlign.xs="start center"
    fxLayoutAlign="start top">
    <button mat-stroked-button fxFlex.gt-xs="none" fxFlex="grow" [matTooltip]="omnisearchResponse.entity | urlTooltip"
        (click)="goToOpportunity()" data-test-id="omnisearch-result-opportunity-view-in-project-button">
        <span class="stroked">View project</span>
    </button>
</div>