import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoAuthService, IUser } from '@techspert-io/auth';
import { Observable, combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { OmnisearchService } from '../../../features/omnisearch/services/omnisearch.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  showOmnisearch = false;
  isOmnisearchAuthorised = false;
  omnisearchSalesCarrot = false;
  innerWidth: number;

  get loggedInUser(): IUser {
    return this.cognitoAuthService.loggedInUser;
  }

  get loggedInAuth0(): Observable<boolean> {
    return this.cognitoAuthService.loggedInAuth0$;
  }

  constructor(
    private cognitoAuthService: CognitoAuthService,
    private omnisearchService: OmnisearchService,
    private router: Router
  ) {
    this.configureOmnisearch();
  }

  ngOnInit(): void {
    combineLatest([
      this.omnisearchService.omnisearchEnabled$,
      this.cognitoAuthService.loggedInConnect$,
    ])
      .pipe(
        tap(([enabled]) => (this.isOmnisearchAuthorised = enabled)),
        filter(([, user]) => !!user),
        tap(([enabled, user]) => {
          const showCarrot = Object.values(user.clients).every(
            (c) =>
              c.contractStatus !== 'Contract' && c.features.salesCarrotEnabled
          );

          this.omnisearchService.setOmniSalesCarrot(!enabled && showCarrot);
        })
      )
      .subscribe();

    this.omnisearchService
      .getShowOmnisearch()
      .pipe(tap((show) => (this.showOmnisearch = show)))
      .subscribe();

    this.omnisearchService.omniseachSalesCarrotEnabled$
      .pipe(tap((enabled) => (this.omnisearchSalesCarrot = enabled)))
      .subscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.configureOmnisearch();
  }

  showOmni(): void {
    this.omnisearchService.setShowOmnisearch(true);
    this.omnisearchService.setFocused(true);
  }

  logout(): void {
    this.cognitoAuthService.logOut().subscribe();
  }

  navigateRoot(): void {
    this.router.navigate(['/']);
  }

  private configureOmnisearch(): void {
    this.innerWidth = window.innerWidth;
    this.omnisearchService.setShowOmnisearch(window.innerWidth > 600);
  }
}
