<div class="advanced-search" *ngIf="showAdvancedSearch$ | async">
  <div class="search-container" fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="column" fxLayoutGap="8px">
        <a *ngIf="showClose" (click)="closeOmnisearch()">
          {{viewedOpportunityName ? 'Back to project' : 'Back'}}
        </a>
        <a *ngIf="searchType === 'query' && recommendedExperts.length && viewedOpportunityName" (click)="clearSearch()">
          Back to similar experts for {{viewedOpportunityName}}
        </a>
        <mat-slide-toggle [checked]="searchByGlobal$ | async" *ngIf="allowGlobalOmnisearch$ | async"
          (change)="onChange($event)">
          <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
            <b class="undiscovered-experts">Show undiscovered experts</b>
            <mat-icon class="tooltip"
              matTooltip="Undiscovered experts are just a sample of new experts that you haven't spoken to before!">
              info_outline</mat-icon>
          </div>
        </mat-slide-toggle>
      </div>
      <ng-container *ngIf="(searchByGlobal$ | async) === false">
        <button *ngIf="searchType !== 'recommended'" class="actions" mat-stroked-button
          (click)="showAdvanced = !showAdvanced">
          <h2 fxLayoutAlign="start center">
            Advanced search
            <mat-icon>{{showAdvanced ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
          </h2>
        </button>
      </ng-container>
    </div>

    <div [fxShow]="showAdvanced" fxLayout="row" fxLayoutGap="8px" fxLayout.lt-md="column-reverse">
      <div fxLayout="row" fxLayoutGap="16px" fxFlex="33" fxFlex.sm="100">
        <form [formGroup]="expertForm" fxLayout="column" fxLayoutGap="4px" fxFlex="50">
          <mat-checkbox (change)="checkExpert($event.checked)" formControlName="experts">
            <b>Experts</b>
          </mat-checkbox>
          <mat-checkbox formControlName="expertise">Expertise</mat-checkbox>
          <mat-checkbox formControlName="name">Name</mat-checkbox>
          <mat-checkbox formControlName="bio">Biography</mat-checkbox>
          <mat-checkbox formControlName="geographicTarget">Geography</mat-checkbox>
          <mat-checkbox formControlName="portalAffiliations">Affiliations</mat-checkbox>
          <mat-checkbox formControlName="profileType">Profile type</mat-checkbox>
        </form>
        <form [formGroup]="opportunityForm" fxLayout="column" fxLayoutGap="4px" fxFlex="50">
          <mat-checkbox (change)="checkOpportunity($event.checked)" formControlName="projects">
            <b>Projects</b>
          </mat-checkbox>
          <mat-checkbox formControlName="name">Title</mat-checkbox>
        </form>
      </div>
      <div class="info" fxFlex="66" fxLayout="row" fxLayoutGap="8px" fxFlex.sm="100">
        <mat-icon class="visible">info_outline</mat-icon>
        <div *ngIf="tooltip">{{tooltip}}</div>
      </div>
    </div>
  </div>
</div>

<div class="page-container" fxLayout="column">
  <div class="get-started" *ngIf="!searchType">
    <h1>Get started with</h1>
    <img alt="Omnisearch" src="../../../../../assets/images/Omnisearch_white-bg.gif">
    <p data-test-id="omnisearch-results-container-omnisearch-introduction">
      Search across your organization's active and historic projects and experts.<br>
      Book calls with historic experts in one click.<br>
      Access information faster!
    </p>
  </div>

  <ng-container *ngIf="!loading">
    <h3 class="result-count" data-test-id="omnisearch-results-summary">
      <ng-container *ngIf="searchType === 'query'">
        <span *ngIf="results.length < resultLimit">Your search returned {{results.length |
          i18nPlural:unitsMapping}}:</span>
        <span *ngIf="results.length >= resultLimit">Top {{results.length}} results for your search:</span>
      </ng-container>
      <span *ngIf="searchType === 'recommended' && viewedOpportunityName">
        Your search for similar experts for '{{viewedOpportunityName}}' returned {{recommendedExperts.length |
        i18nPlural:unitsMapping}}:
      </span>
    </h3>

    <div class="no-results" *ngIf="!results.length && searchType === 'query'" data-test-id="omnisearch-results-summary"
      fxLayout="column" fxLayoutAlign='center center' fxLayoutGap="16px">
      <h1>It looks like we haven't connected you with experts matching those search terms.</h1>
      <span>Send us a project request and we can get started.</span>

      <button mat-flat-button color="primary" (click)="request()">
        <span fxLayout="row" fxLayoutAlign='start center' fxLayoutGap="6px">
          <mat-icon>mail_outline</mat-icon>
          <span>SUBMIT A REQUEST</span>
        </span>
      </button>
    </div>

    <app-omnisearch-result *ngFor="let res of searchType === 'recommended' ? recommendedExperts : results"
      fxLayout="column" fxLayoutGap="4px" (tabChange)="tabChange.emit($event)" [omnisearchResponse]="res"
      data-test-id="omnisearch-results-container-result-item">
    </app-omnisearch-result>
  </ng-container>
</div>