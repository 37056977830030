import { Component, EventEmitter, Output } from '@angular/core';
import { PopupStages } from '../omnisearch-sales-popup.component';

@Component({
  selector: 'app-omnisearch-sales-pitch',
  templateUrl: './omnisearch-sales-pitch.component.html',
  styleUrls: ['./omnisearch-sales-pitch.component.scss'],
})
export class OmnisearchSalesPitchComponent {
  @Output() popupPage = new EventEmitter<PopupStages>();
}
