import { Pipe, PipeTransform } from '@angular/core';
import { OmnisearchEntity } from '../models/omnisearch.models';

@Pipe({
  name: 'entityIcon',
})
export class EntityIconPipe implements PipeTransform {
  transform(entity: OmnisearchEntity): string {
    return {
      portalExperts: 'school',
      opportunities: 'insert_chart_outlined',
    }[entity];
  }
}
