import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IExpertFile } from '@techspert-io/files';
import { OpportunityNavigationService } from '@techspert-io/navigation';
import { combineLatest, Observable } from 'rxjs';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';
import {
  Add,
  Delete,
  ExpertFilesStateService,
  UpdateProgress,
} from '../../../../../shared/services/filestore-state.service';
import {
  FileStoreService,
  IFileProgressEvent,
} from '../../../../../shared/services/filestore.service';
import { IExpertPhases } from '../../../../../shared/state/expert.service';
import { IOmnisearchExpert } from '../../../models/omnisearch.models';
import { OmnisearchService } from '../../../services/omnisearch.service';

interface IAnimationState {
  favouriteState: 'bounceStart' | 'bounceEnd';
}

@Component({
  selector: 'app-client-result',
  templateUrl: './client-result.component.html',
  styleUrls: ['../omnisearch-result.component.scss'],
  animations: [
    trigger('bounce', [
      state(
        'bounceStart',
        style({
          transform: 'scale(1)',
        })
      ),
      state(
        'bounceEnd',
        style({
          transform: 'scale(1)',
        })
      ),
      transition(
        'bounceStart => bounceEnd',
        animate(
          '100ms ease-in',
          keyframes([
            style({ transform: 'scale(0.7)' }),
            style({ transform: 'scale(1)' }),
          ])
        )
      ),
    ]),
  ],
})
export class ClientResultComponent {
  animationState: IAnimationState = { favouriteState: 'bounceEnd' };

  @Input() omnisearchResponse: IOmnisearchExpert;
  @Input() engagementRequested: boolean;
  @Output() tabChange = new EventEmitter<number>();
  @Output() requestExpertEngagement = new EventEmitter<IOmnisearchExpert>();

  loadingFiles$ = this.filesState.state$;
  downloadedFiles: Record<string, boolean> = {};
  showAttachments = false;

  get searchTerm(): string {
    return this.omnisearchService.searchForm.get('search').value;
  }

  constructor(
    private router: Router,
    private omnisearchService: OmnisearchService,
    private filesState: ExpertFilesStateService,
    private fileStore: FileStoreService,
    private opportunityNavigationState: OpportunityNavigationService
  ) {}

  requestEngagement(): void {
    this.requestExpertEngagement.emit(this.omnisearchResponse);
  }

  bounceEnd(): void {
    this.animationState.favouriteState = 'bounceEnd';
  }

  onToggleFavourited(expert: IOmnisearchExpert): void {
    this.animationState.favouriteState = 'bounceStart';

    this.omnisearchService
      .favouriteExpert(expert.id, expert.favourited)
      .pipe(tap(() => (expert.favourited = !expert.favourited)))
      .subscribe();
  }

  navigateToExpert(entity: IOmnisearchExpert): void {
    this.omnisearchService.setFocused(false);
    this.omnisearchService.clearRecommendedExperts();

    const portalPhases: (keyof IExpertPhases)[] = [
      'newExperts',
      'acceptedExperts',
      'completedConnections',
      'declinedExperts',
    ];

    const newTab = portalPhases.indexOf(entity.portalPhase) + 1;
    this.opportunityNavigationState.setTabState(newTab);

    this.opportunityNavigationState.setNavigationState({ [entity.id]: true });

    this.tabChange.emit(newTab);

    this.router.navigate(['connect', entity.oppId], {
      replaceUrl: false,
    });
  }

  downloadAll(files: IExpertFile[]): void {
    combineLatest(files.map((f) => this.downloadFile(f))).subscribe();
  }

  download(file: IExpertFile): void {
    this.downloadFile(file).subscribe();
  }

  private downloadFile(file: IExpertFile): Observable<IFileProgressEvent> {
    this.filesState.dispatch(new Add(file.fileKey));
    return this.fileStore
      .downloadExpertFile(
        file.fileKey,
        `${file.fileName}.${file.fileExtension}`
      )
      .pipe(
        takeUntil(this.loadingFiles$.pipe(filter((d) => !d[file.fileKey]))),
        tap((event) =>
          this.filesState.dispatch(
            new UpdateProgress(file.fileKey, event.percentageComplete)
          )
        ),
        finalize(() => {
          this.downloadedFiles[file.fileKey] = true;
          this.filesState.dispatch(new Delete(file.fileKey));
        })
      );
  }
}
