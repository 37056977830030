<div class="omnisearch-item" fxLayout="row" fxLayoutGap="8px">
  <div class="result-container" fxLayout.xs="column" fxLayout="row" fxLayoutGap="8px">
    <div class="info" fxLayout="row" fxFlex="grow" fxLayoutGap="12px" fxLayoutAlign="start top">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img src="../../../../../assets/images/undiscovered_expert.svg" matTooltip="Undiscovered Expert">
      </div>
      <div fxLayout="column" fxLayoutGap="8px">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="title" [innerHTML]="omnisearchResponse.name | searchHighlight: searchTerm | safeHtml"
            data-test-id="omnisearch-result-expert-name">
          </div>
        </div>
        <div class="geo-expertise">
          <div class="geo-profile" *ngIf="omnisearchResponse.geographicTarget && omnisearchResponse.profileType"
            fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center"
            data-test-id="omnisearch-result-geo-target-and-profile-type">
            <span [innerHTML]="omnisearchResponse.geographicTarget | searchHighlight: searchTerm | safeHtml"
              data-test-id="omnisearch-result-geo-target"></span>
            <span [innerHTML]="omnisearchResponse.profileType | searchHighlight: searchTerm | safeHtml"
              data-test-id="omnisearch-result-profile-type"></span>
          </div>
          <div class="expertise" *ngFor="let expertise of omnisearchResponse.expertise"
            [innerHTML]="expertise | lowercase | searchHighlight: searchTerm | safeHtml"
            data-test-id="omnisearch-result-expert-expertise">
          </div>
        </div>
      </div>
    </div>
    <div class="bio-container" fxLayout="column" fxLayoutAlign="start top" fxLayoutGap="8px"
      *ngIf="omnisearchResponse.portalAffiliations.length" data-test-id="omnisearch-result-expert-bio">
      <div class="affiliation" [innerHTML]="omnisearchResponse.portalAffiliations | safeHtml">
      </div>
    </div>
    <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start top">
      <button mat-flat-button fxFlex.gt-xs="none" fxFlex="grow"
        [ngClass]="{'primary': !engagementRequested, 'success': engagementRequested}" (click)="requestEngagement()"
        [disabled]="engagementRequested" data-test-id="omnisearch-result-request-engagement-button">
        <span *ngIf="engagementRequested">Call requested</span>
        <span *ngIf="!engagementRequested">Request a call</span>
      </button>

      <button mat-stroked-button fxFlex.gt-xs="none" fxFlex="grow" (click)="requestInformation()"
        data-test-id="omnisearch-result-request-information-button">
        <span class="stroked">Request information</span>
      </button>
    </div>
  </div>
</div>