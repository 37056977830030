<ng-container *ngIf="loadingFiles$ | async as loadingFiles">
  <div class="file" *ngFor="let file of files">

    <div class="file-in-progress" *ngIf="loadingFiles[file.fileKey]">
      <span>{{loadingFiles[file.fileKey].progress}}%</span>
      <mat-icon class="file-icon" (click)="cancelDownload(file.fileKey)">
        cancel
      </mat-icon>
    </div>

    <div [ngClass]="{'dimmed': loadingFiles[file.fileKey]}" fxLayout="row" fxLayoutGap="8px"
      fxLayoutAlign="start center" matTooltip="Please log in to download files" [matTooltipDisabled]="isAuthorised"
      (click)="downloadFile(file)">

      <mat-icon class="icon-color">
        {{file | fileDisplayIcon }}
      </mat-icon>

      <span fxFlex="auto" [matTooltip]="file | fileDisplayName" [matTooltipDisabled]="!isAuthorised">
        {{file | fileDisplayName:34}}
      </span>

      <mat-icon class="icon-color">
        {{
        loadingFiles[file.fileKey]
        ? 'downloading'
        : downloadedFiles[file.fileKey]
        ? 'download_done'
        : 'download'
        }}
      </mat-icon>
    </div>
  </div>
  <br>
</ng-container>