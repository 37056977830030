import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { OpportunityService } from '../features/opportunities/services/opportunity.service';
import { LostUserSvgComponent } from './components/landing-page/images/lost-user-svg.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoaderComponent } from './components/loader/loader.component';
import { NotAuthorisedSvgComponent } from './components/not-authorised/images/not-authorised-svg.component';
import { NotAuthorisedComponent } from './components/not-authorised/not-authorised.component';
import {
  AvailabilityDatePipe,
  AvailabilityTimePipe,
  getLocale,
  getTimezone,
  getTimezoneAbbr,
  TIME_ZONE,
  TIME_ZONE_ABBR,
} from './pipes/availability-date.pipe';
import { FileStoreService, FILESTORE_URL } from './services/filestore.service';
import { NotificationService } from './services/notification.service';

@NgModule({
  declarations: [
    LoaderComponent,
    AvailabilityDatePipe,
    AvailabilityTimePipe,
    NotAuthorisedComponent,
    NotAuthorisedSvgComponent,
    LandingPageComponent,
    LostUserSvgComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    FlexLayoutModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    MatTableModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    RouterModule,
    MatTabsModule,
    MatBadgeModule,
  ],
  exports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatTooltipModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatMenuModule,
    RouterModule,
    MatTabsModule,
    LoaderComponent,
    AvailabilityDatePipe,
    AvailabilityTimePipe,
    FlexLayoutModule,
    NotAuthorisedSvgComponent,
  ],
  providers: [
    OpportunityService,
    FileStoreService,
    NotificationService,
    DatePipe,
    {
      provide: FILESTORE_URL,
      useValue: environment.filestoreEndpoint,
    },
    {
      provide: LOCALE_ID,
      useFactory: getLocale,
    },
    {
      provide: TIME_ZONE,
      useFactory: getTimezone,
    },
    {
      provide: TIME_ZONE_ABBR,
      useFactory: getTimezoneAbbr,
      deps: [TIME_ZONE],
    },
  ],
})
export class SharedModule {}
