<div fxLayout="row" fxLayoutAlign="space-between center">
  <h3 data-test-id="request-expert-engagement-title">
    {{['booking', 'booking_global'].includes(data.bookingType) ?
    'Request a new consultation' : 'Request additional information'}}
  </h3>
  <button mat-icon-button mat-dialog-close data-test-id="request-expert-engagement-close">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<form [formGroup]="engagementForm" (submit)="sendRequest()" fxLayout="column" fxLayoutGap="8px">
  <mat-form-field appearance="outline">
    <mat-label>Is this for a new or existing project?</mat-label>
    <mat-select formControlName="project" data-test-id="request-expert-engagement-project-select">
      <mat-option [value]="0" data-test-id="request-expert-engagement-new-project-option">A new project</mat-option>
      <mat-option *ngFor="let opp of data.opps" [value]="opp"
        data-test-id="request-expert-engagement-existing-project-option">
        {{opp.opportunityName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Segment</mat-label>
    <mat-select formControlName="segmentId" data-test-id="request-expert-engagement-segment-select">
      <ng-container *ngIf="segments$ | async as segments">
        <mat-option *ngFor="let segment of segments" [value]="segment.id"
          data-test-id="request-expert-engagement-segment-option">
          {{segment.geography}} - {{segment.segment}}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Please add any further context or detail for this request</mat-label>
    <textarea matInput formControlName="notes" [cdkTextareaAutosize]="false" [placeholder]="data.bookingType === 'booking' 
        ? 'Include your availability, if you\'d like us to custom recruit any more individuals...' 
        : 'Please let us know if you have any specific questions about this expert'"
      data-test-id="request-expert-engagement-comment"></textarea>
  </mat-form-field>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
    <button fxFlex="50%" type="button" mat-stroked-button mat-dialog-close color="primary" class="primaryColor-border">
      Close window
    </button>
    <button fxFlex="50%" type="submit" mat-flat-button color="primary" [disabled]="!engagementForm.valid"
      data-test-id="request-expert-engagement-submit">
      Send request
    </button>
  </div>
</form>