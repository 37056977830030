<div class="omnisearch-item" fxLayout="row" fxLayoutGap="8px">
  <div *ngIf="loadingFiles$ | async as loadingFiles" class="result-container" fxLayout.xs="column" fxLayout="row"
    fxLayoutGap="8px">
    <div class="info" fxLayout="row" fxFlex="grow" fxLayoutGap="12px" fxLayoutAlign="start top">
      <div fxLayout="column" fxLayoutAlign="center center">

        <container-element [ngSwitch]="omnisearchResponse.portalPhase">
          <img src="../../../../../assets/images/new_expert.svg" matTooltip="New profile" *ngSwitchCase="'newExperts'">

          <img src="../../../../../assets/images/scheduled_expert.svg" matTooltip="Upcoming Connection"
            *ngSwitchCase="'acceptedExperts'">

          <img src="../../../../../assets/images/completed_expert.svg" matTooltip="Completed Connection"
            *ngSwitchCase="'completedConnections'">

          <img src="../../../../../assets/images/rejected_expert.svg" matTooltip="Rejected Profile"
            *ngSwitchCase="'declinedExperts'">
        </container-element>

        <mat-icon [@bounce]="animationState['favouriteState']"
          *ngIf="omnisearchResponse.isClientExpert || omnisearchResponse.portalPhase"
          (@bounce.done)="bounceEnd('favouriteState')" [class.txpPink]="omnisearchResponse.favourited"
          (click)="onToggleFavourited(omnisearchResponse)">
          {{ omnisearchResponse.favourited ? 'favorite' : 'favorite_outline' }}
        </mat-icon>

      </div>
      <div fxLayout="column" fxLayoutGap="8px">

        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="title" [innerHTML]="omnisearchResponse.name | searchHighlight: searchTerm | safeHtml"
            data-test-id="omnisearch-result-expert-name">
          </div>
          <mat-icon *ngIf="omnisearchResponse.files.length && !showAttachments" fxShow.gt-xs="false" class="file-menu"
            (click)="showAttachments = true">
            attach_file
          </mat-icon>
          <mat-icon *ngIf="omnisearchResponse.files.length" fxShow.lt-md="false" [matMenuTriggerFor]="menu"
            class="file-menu">
            attach_file
          </mat-icon>
          <mat-menu #menu="matMenu">
            <button mat-menu-item class="menu-button" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
              [class.dimmed]="loadingFiles[file.fileKey]" (click)="download(file)"
              *ngFor="let file of omnisearchResponse.files">

              <ng-container [ngTemplateOutlet]="fileImpl"
                [ngTemplateOutletContext]="{file, loadingFiles, dropdown: true}">

              </ng-container>
            </button>
            <button mat-menu-item (click)="downloadAll(omnisearchResponse.files)">
              <span class="file-name">Download all</span>
            </button>
          </mat-menu>
        </div>

        <div class="geo-expertise">
          <div class="opp-name" *ngIf="omnisearchResponse.oppName?.includes('-')" fxLayout="row"
            fxLayoutAlign="start center" data-test-id="omnisearch-result-expert-opportunity-name"
            [innerHTML]="omnisearchResponse.oppName.split('-')[0].trim() | searchHighlight: searchTerm | safeHtml">
          </div>
          <div class="geo-profile" *ngIf="omnisearchResponse.geographicTarget && omnisearchResponse.profileType"
            fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center"
            data-test-id="omnisearch-result-geo-target-and-profile-type">
            <span [innerHTML]="omnisearchResponse.geographicTarget | searchHighlight: searchTerm | safeHtml"
              data-test-id="omnisearch-result-geo-target"></span>
            <span [innerHTML]="omnisearchResponse.profileType | searchHighlight: searchTerm | safeHtml"
              data-test-id="omnisearch-result-profile-type"></span>
          </div>
          <div class="expertise" *ngFor="let expertise of omnisearchResponse.expertise | slice:0:5"
            [innerHTML]="expertise | lowercase | searchHighlight: searchTerm | safeHtml"
            data-test-id="omnisearch-result-expert-expertise">
          </div>
        </div>
      </div>
    </div>
    <div class="bio-container" fxLayout="column" fxLayoutAlign="start top" fxLayoutGap="8px"
      *ngIf="omnisearchResponse.bio" data-test-id="omnisearch-result-expert-bio">
      <div class="affiliation" [innerHTML]="omnisearchResponse.portalAffiliations | safeHtml">
      </div>
      <span class="bio"
        [innerHTML]="omnisearchResponse.bio | bioEllipsis: omnisearchResponse.bio | searchHighlight: searchTerm | safeHtml">
      </span>
    </div>
    <ng-container *ngIf="showAttachments">
      <div fxShow.gt-xs="false" *ngFor="let file of omnisearchResponse.files">
        <div [class.dimmed]="loadingFiles[file.fileKey]" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center"
          (click)="download(file)">

          <ng-container [ngTemplateOutlet]="fileImpl" [ngTemplateOutletContext]="{file, loadingFiles, dropdown: false}">
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div fxLayout="column" fxLayoutGap="4px" fxLayoutAlign="start top">
      <button mat-flat-button fxFlex.gt-xs="none" fxFlex="grow"
        [ngClass]="{'primary': !engagementRequested, 'success': engagementRequested}" (click)="requestEngagement()"
        [disabled]="engagementRequested" data-test-id="omnisearch-result-request-engagement-button">
        <span *ngIf="engagementRequested">Call requested</span>

        <span *ngIf="!engagementRequested">
          {{omnisearchResponse.portalPhase === 'completedConnections' ? 'Request another call' : 'Request a call'}}
        </span>
      </button>
      <button mat-stroked-button fxFlex.gt-xs="none" fxFlex="grow" *ngIf="omnisearchResponse.portalPhase"
        [matTooltip]="omnisearchResponse.entity | urlTooltip" (click)="navigateToExpert(omnisearchResponse)"
        data-test-id="omnisearch-result-view-in-project-button">
        <span class="stroked">View in project</span>
      </button>
    </div>
  </div>
</div>

<ng-template #fileImpl let-file="file" let-loadingFiles="loadingFiles" let-dropdown="dropdown">
  <mat-icon *ngIf="!dropdown" class="icon-color">
    {{file | fileDisplayIcon }}
  </mat-icon>

  <span fxFlex="auto" class="file-name" [matTooltip]="file | fileDisplayName">
    {{file | fileDisplayName:34}}
  </span>

  <mat-icon class="icon-color" style="margin-right: 0 !important">
    {{loadingFiles[file.fileKey] ? 'downloading' : downloadedFiles[file.fileKey] ? 'download_done' : 'download'}}
  </mat-icon>
</ng-template>