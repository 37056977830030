import { Component } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-share-expert-dialog',
  templateUrl: './share-expert-dialog.component.html',
  styles: [
    `
      h3 {
        margin-bottom: 0;
        margin-top: 0;
      }
      .chip-text {
        font-weight: 400;
      }
      mat-error {
        padding-top: 8px;
      }
    `,
  ],
})
export class ShareExpertDialogComponent {
  submissionForm = new FormGroup({
    recipients: new FormControl<string[]>(
      [],
      [Validators.required, Validators.minLength(1), this.emailsValidator()]
    ),
    notes: new FormControl<string>(null, Validators.required),
  });

  get submissionFormEmails(): string[] {
    return this.submissionForm.get('recipients').value;
  }

  get recipientsControl(): FormControl<string[]> {
    return this.submissionForm.controls.recipients;
  }

  get notesControl(): FormControl<string> {
    return this.submissionForm.controls.notes;
  }

  constructor(private dialogRef: MatDialogRef<ShareExpertDialogComponent>) {}

  submit(): void {
    this.submissionForm.markAllAsTouched();

    if (this.submissionForm.valid) {
      this.dialogRef.close(this.submissionForm.value);
    }
  }

  removeEmail(email: string): void {
    const filteredEmails = this.submissionForm.controls.recipients.value.filter(
      (e) => e !== email
    );

    this.submissionForm.controls.recipients.setValue(filteredEmails);
    this.submissionForm.controls.recipients.updateValueAndValidity();
  }

  addEmail(event: MatChipInputEvent): void {
    if (!event.value) {
      return;
    }

    event.chipInput.clear();

    this.submissionForm.controls.recipients.value.push(event.value);
    this.submissionForm.controls.recipients.updateValueAndValidity();
  }

  private emailsValidator(): ValidatorFn {
    return (control: FormControl<string[]>): ValidationErrors | null =>
      control.value.every((v) =>
        /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(v.toLowerCase())
      )
        ? null
        : { emailValidator: 'All emails must be valid' };
  }
}
