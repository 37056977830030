import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoAuthService } from '@techspert-io/auth';
import { EMPTY } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {
  IContractRequest,
  OmnisearchService,
} from '../../../services/omnisearch.service';
import { PopupStages } from '../omnisearch-sales-popup.component';

@Component({
  selector: 'app-omnisearch-sales-enquiry-form',
  templateUrl: './omnisearch-sales-enquiry-form.component.html',
  styleUrls: ['./omnisearch-sales-enquiry-form.component.scss'],
})
export class OmnisearchSalesEnquiryFormComponent implements OnInit {
  @Output() popupPage = new EventEmitter<PopupStages>();

  loading = false;

  salesForm = new FormGroup({
    fullName: new FormControl<string>('', Validators.required),
    emailAddress: new FormControl<string>('', Validators.required),
    phoneNumber: new FormControl<string>('', Validators.required),
    accountName: new FormControl<string>('', Validators.required),
    additionalComments: new FormControl<string>(''),
  });

  constructor(
    private cognitoAuthService: CognitoAuthService,
    private omnisearchService: OmnisearchService
  ) {}

  ngOnInit(): void {
    const user = this.cognitoAuthService.loggedInUser;

    const accountName =
      Object.values(user.clients).find(Boolean)?.clientName || '';

    this.salesForm.setValue({
      fullName: `${user.firstName} ${user.lastName}`,
      emailAddress: user.email,
      phoneNumber: '',
      accountName,
      additionalComments: '',
    });
  }

  submitRequest(): void {
    if (this.salesForm.valid) {
      this.loading = true;
      this.omnisearchService
        .enquireContract(this.salesForm.value as IContractRequest)
        .pipe(
          tap(() => this.popupPage.emit('success')),
          catchError(() => {
            this.popupPage.emit('fail');
            return EMPTY;
          })
        )
        .subscribe();
    }
  }
}
