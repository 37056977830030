<div class="header" fxLayoutAlign="center center">
  <h1>Introducing Omnisearch...</h1>
</div>

<div class="sales-list">
  <div fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="center">
    <div fxLayout="column" fxFlex class="sales-prompts">
      <h3>How does this benefit me?
      </h3>
      <h4 fxLayoutAlign="start center">
        <mat-icon>bookmark_border</mat-icon>
        Library of insights
      </h4>
      <h4 fxLayoutAlign="start center">
        <mat-icon>touch_app</mat-icon>
        Experts on demand
      </h4>
    </div>

    <div fxLayout="column" fxFlex class="sales-prompts">
      <h3>Giving you access to:</h3>
      <h4 fxLayoutAlign="start center">
        <mat-icon>replay</mat-icon>
        Rebook past experts
      </h4>
      <h4 fxLayoutAlign="start center">
        <mat-icon>favorite_border</mat-icon>
        25k+ sample experts
      </h4>
    </div>
  </div>
</div>

<h3 fxLayoutAlign="center" [style.padding]="'1rem'">Talk to Simon about upgrading now?</h3>

<div>
  <button mat-flat-button (click)="popupPage.emit('enquiry')" fxFlex color="primary">
    Send an enquiry
  </button>
</div>