<div fxLayout="column" class="expert-container" fxLayoutGap="8px">
  <div fxLayoutGap="16px" fxFill>
    <div fxFlex="212px" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start center">
      <ng-container [ngTemplateOutlet]="expertInfoTmpl"></ng-container>
      <ng-container [ngTemplateOutlet]="expertTimelineTmpl"></ng-container>
      <ng-container *ngIf="isExpertApprovalRequired" [ngTemplateOutlet]="expertApprovalActionTmpl"></ng-container>
      <ng-container *ngIf="smallScreen" [ngTemplateOutlet]="expertActionsTmpl"></ng-container>
    </div>

    <div fxFlex="auto">
      <ng-container [ngTemplateOutlet]="expertDetailsTmpl"></ng-container>
    </div>

    <div fxFlex="400px" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start end" *ngIf="!smallScreen">
      <ng-container [ngTemplateOutlet]="expertActionsTmpl"></ng-container>
    </div>
  </div>

  <div fxLayoutGap="16px" fxFill>
    <div fxFlex="212px" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center">
      <ng-container [ngTemplateOutlet]="expertSheduledActionTmpl"></ng-container>
    </div>

    <div fxFlex="auto">
      <ng-container [ngTemplateOutlet]="expertAvailabilityTmpl"></ng-container>
    </div>
  </div>
</div>

<ng-template #expertInfoTmpl>
  <div class="expert-icon">
    <app-expert-hexagon [viewStage]="expertIcon" [tagline]="expertData.tagline"></app-expert-hexagon>
  </div>
</ng-template>

<ng-template #expertTimelineTmpl>
  <app-expert-timeline [expert]="expertData" [viewStage]="viewStage"></app-expert-timeline>
</ng-template>

<ng-template #expertApprovalActionTmpl>
  <div>
    <button *ngIf="['new', 'accepted'].includes(viewStage) && !isCallInProgress" mat-flat-button color="primary"
      class="text-span" (click)="toggleApproval(expertData)" data-test-id="expert-toggle-approval-button">
      <mat-icon class="secondaryColor" data-test-id="expert-toggle-approval-button-state-icon">
        {{expertData.clientApproved ? 'lock' : 'lock_open'}}
      </mat-icon>
      <span data-test-id="expert-toggle-approval-button-text">
        {{expertData.clientApproved ? 'Withdraw approval' : 'Approve expert'}}
      </span>
    </button>
  </div>
</ng-template>

<ng-template #expertDetailsTmpl>
  <div class="mat-group-custom-style">
    <mat-tab-group color="primary" #expertTabGroup dynamicHeight
      [selectedIndex]="!isFileDownloadAuthorised && expertData.screenerResponse ? 1 : 0">
      <mat-tab *ngIf="expertData.screenerResponse" [disabled]="!isFileDownloadAuthorised">
        <ng-template mat-tab-label>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" (click)="onAuthTabClick()"
            matTooltip="Please log in to view screener response" [matTooltipDisabled]="isFileDownloadAuthorised">
            <mat-icon class="tab-icon">fact_check</mat-icon>
            <span class="expert-header-title" data-test-id="expert-screener-tab-select">Screener</span>
          </div>
        </ng-template>

        <app-expert-text *ngIf="isFileDownloadAuthorised" [text]="expertData.screenerResponse"
          data-test-id="expert-screener-response">
        </app-expert-text>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <mat-icon class="tab-icon">person_pin_outline</mat-icon>
            <span class="expert-header-title" data-test-id="expert-biography-tab-select">Biography</span>
          </div>
        </ng-template>
        <app-expert-text [text]="expertData.bio" data-test-id="expert-biography-text"></app-expert-text>
      </mat-tab>

      <mat-tab [disabled]="!isFileDownloadAuthorised">
        <ng-template mat-tab-label>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" (click)="onAuthTabClick()"
            matTooltip="Please log in to view comments" [matTooltipDisabled]="isFileDownloadAuthorised">
            <mat-icon class="tab-icon">comment</mat-icon>
            <span class="expert-header-title" data-test-id="expert-comments-tab-select">Comments</span>
          </div>
        </ng-template>

        <div class="expert-comments">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Comments</mat-label>
            <textarea matInput #clientNotes="ngModel" [ngModel]="expertData.clientNotes"
              data-test-id="expert-comments-text-area"></textarea>
          </mat-form-field>
          <button mat-flat-button [disabled]="clientNotes.pristine" color="primary"
            (click)="saveClientNotes(clientNotes.value)" data-test-id="expert-save-comments">
            Save Comment
          </button>
        </div>
      </mat-tab>

      <mat-tab *ngIf="smallScreen">
        <ng-template mat-tab-label>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <mat-icon class="tab-icon">download</mat-icon>
            <span class="expert-header-title">Downloads</span>
          </div>
        </ng-template>
        <div fxFlex="400px" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="start end">
          <app-expert-files class="full-width" style="padding-top: 16px" [files]="expertData.uploadedFiles"
            [isAuthorised]="isFileDownloadAuthorised" [filePrefix]="expertFilePrefix">
          </app-expert-files>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

<ng-template #expertActionsTmpl>
  <div class="expert-header" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center">
    <div fxFlex="auto" fxLayoutGap="8px" fxLayoutAlign="start center" *ngIf="!smallScreen">
      <mat-icon>download</mat-icon>
      <span class="expert-header-title">Downloads</span>
    </div>

    <ng-container [ngTemplateOutlet]="expertOnHoldImpl"></ng-container>
    <ng-container [ngTemplateOutlet]="expertRejectTmpl"></ng-container>
  </div>

  <app-expert-files *ngIf="!smallScreen" class="full-width" [files]="expertData.uploadedFiles"
    [isAuthorised]="isFileDownloadAuthorised" [filePrefix]="expertFilePrefix">
  </app-expert-files>
</ng-template>

<ng-template #expertRejectTmpl>
  <div>
    <button mat-icon-button class="font-grey"
      *ngIf="(!expertData.callTime && viewStage === 'new') || viewStage === 'declined'"
      [matTooltip]="expertData.clientRejected ? 'Unreject Expert' : 'Reject Expert'"
      (click)="toggleRejectExpert(!expertData.clientRejected, expertData.expertId)" data-test-id="expert-reject-expert">
      <mat-icon>{{expertData.clientRejected ? 'undo' : 'thumb_down'}}</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #expertOnHoldImpl>
  <div>
    <button mat-icon-button class="font-grey" *ngIf="viewStage === 'new'" matTooltip="On hold"
      (click)="toggleOnHold(!expertData.onHold, expertData.expertId)" data-test-id="expert-set-hold-status">
      <app-on-hold-svg colour="#b4becb" [large]="true" *ngIf="!expertData.onHold">
      </app-on-hold-svg>
      <mat-icon *ngIf="expertData.onHold">undo</mat-icon>
    </button>
  </div>
</ng-template>

<ng-template #expertSheduledActionTmpl>
  <button mat-flat-button color="primary" class="text-span"
    *ngIf="(viewStage === 'accepted' || viewStage === 'completed') && isCallInProgress"
    (click)="expertNoShow(expertData)" data-test-id="expert-report-no-show-button">
    Report expert no show
  </button>

  <button mat-flat-button color="primary" class="text-span"
    *ngIf="isFileDownloadAuthorised && (viewStage === 'completed' || (opportunity && ['To be invoiced', 'Closed Won', 'Closed Lost'].includes(opportunity.stageName)))"
    (click)="requestExpertEngagement(expertData)">
    {{viewStage === 'completed' ? 'Speak to this expert again' : 'Speak to this expert'}}
  </button>
  <div *ngIf="requestNewEngagementSent">Your request for new engagement has been received</div>

  <button mat-flat-button color="primary" class="text-span" (click)="shareExpert(expertData)"
    data-test-id="expert-share-expert">
    Share expert
  </button>
</ng-template>

<ng-template #expertAvailabilityTmpl>
  <ng-container *ngIf="viewStage !== 'completed'">
    <div *ngFor="let day of expertAvailability; let i = index" class="expert-availability" fxLayoutAlign="start center">

      <div class="expert-availability-title" fxFlex="230px" data-test-id="expert-availability-date">
        {{day.date | availabilityDate}}
      </div>

      <div class="availability-container" fxLayout="row wrap" fxLayoutAlign="start center">
        <button mat-stroked-button *ngFor="let availability of day.times"
          [ngClass]="selectedAvailability === availability ? 'mat-flat-button' : 'mat-stroked-button'" color="primary"
          (click)="acceptAvailability(availability)"
          [disabled]="isAcceptAvailabilityInProgress || viewStage !== 'new' || isPastDate(availability)"
          data-test-id="expert-availability-time">
          <mat-icon *ngIf="!(viewStage !== 'new' || isPastDate(availability)) && isExpertApprovalRequired &&
          !expertData.clientApproved" data-test-id="expert-availability-acceptance-locked">
            lock
          </mat-icon>
          {{availability | availabilityTime}}
        </button>
      </div>
    </div>

    <div class="expert-availability" fxLayoutAlign="start center" fxLayoutGap="8px">
      <span *ngIf="viewStage === 'new'" fxFlex="230px">
        {{expertData.availabilities?.length ?
        'Can\'t attend these times?' : 'Want to speak with this expert?'}}
      </span>
      <button mat-stroked-button color="primary" (click)="requestDifferentTime(expertData)" *ngIf="viewStage === 'new'"
        data-test-id="expert-request-consultation-time">
        <mat-icon *ngIf=" isExpertApprovalRequired && !expertData.clientApproved"
          data-test-id="expert-request-consultation-time-locked">
          lock
        </mat-icon>
        <span data-test-id="expert-request-consultation-time-button-text">Request consultation time</span>
      </button>
      <span *ngIf="expertData.requestedAnotherTime" data-test-id="expert-consultation-time-requested">
        Your request for new call times has been received
      </span>
    </div>

  </ng-container>
</ng-template>