<div fxFlex="100%" fxLayout="column" fxLayoutGap="8px" data-test-id="share-expert-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h3 data-test-id="share-expert-dialog-title">Share expert</h3>
    <button mat-icon-button mat-dialog-close>
      <mat-icon data-test-id="share-expert-dialog-close">clear</mat-icon>
    </button>
  </div>

  <form [formGroup]="submissionForm" fxLayout="column" fxLayoutGap="8px">
    <mat-form-field appearance="outline">
      <mat-label>Recipient(s)</mat-label>
      <mat-chip-list #chipList formControlName="recipients" data-test-id="share-expert-dialog-recipients">
        <mat-chip *ngFor="let email of submissionFormEmails" [removable]="true" (removed)="removeEmail(email)"
          data-test-id="chip-list-item">
          <span class="chip-text">{{email}}</span>
          <mat-icon matChipRemove data-test-id="chip-list-item-remove">cancel</mat-icon>
        </mat-chip>
        <input [matChipInputFor]="chipList" (matChipInputTokenEnd)="addEmail($event)" />
      </mat-chip-list>
      <mat-error *ngIf="recipientsControl.hasError('required')" data-test-id="share-expert-dialog-recipients-error">One
        valid email is required
      </mat-error>
      <mat-error *ngIf="recipientsControl.hasError('emailValidator')"
        data-test-id="share-expert-dialog-recipients-error">All emails must be valid</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Message to recipient(s)</mat-label>
      <textarea matInput style="min-height: 200px; resize: none" placeholder="Please could you review this expert"
        formControlName="notes" data-test-id="share-expert-dialog-message"></textarea>
      <mat-error data-test-id="share-expert-dialog-message-error">Message is required</mat-error>
    </mat-form-field>
  </form>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
    <button fxFlex type="button" mat-stroked-button mat-dialog-close color="primary"
      data-test-id="share-expert-dialog-cancel" class="primaryColor-border">
      Close window
    </button>
    <button fxFlex type="submit" mat-flat-button color="primary" (click)="submit()"
      data-test-id="share-expert-dialog-confirm">
      Confirm
    </button>
  </div>
</div>