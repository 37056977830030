import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OmnisearchSalesPopupComponent } from '../omnisearch-sales-popup/omnisearch-sales-popup.component';

@Component({
  selector: 'app-omnisearch-sales-incentive',
  template: `<div
    class="omnisearch-container"
    fxLayout="row"
    fxLayoutAlign="start center"
    (click)="openDialog()"
  >
    <div
      class="search-input"
      fxLayout="row"
      fxLayoutGap="4px"
      fxLayoutAlign="start center"
    >
      <mat-icon class="search-input-icon">info_outline</mat-icon>
      <input
        class="search-input-input"
        placeholder="Discover Omnisearch..."
        [disabled]="true"
      />
    </div>
  </div>`,
  styleUrls: ['./../omnisearch/omnisearch.component.scss'],
})
export class OmnisearchSalesIncentiveComponent {
  constructor(private dialog: MatDialog) {}

  openDialog(): void {
    this.dialog.open(OmnisearchSalesPopupComponent, {
      width: '80vh',
      minHeight: '200px',
    });
  }
}
