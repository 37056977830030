import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExpertFile } from '@techspert-io/expert-files';
import { from, Subject } from 'rxjs';
import {
  filter,
  finalize,
  map,
  mergeMap,
  share,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  Add,
  Delete,
  ExpertFilesStateService,
  UpdateProgress,
} from '../../../../../shared/services/filestore-state.service';
import { FileStoreService } from '../../../../../shared/services/filestore.service';
import { AuthDialogComponent } from '../../../components/auth-dialog/auth-dialog.component';

@Component({
  selector: 'app-screener-btn',
  template: `<ng-container *ngIf="screenerFiles.length">
    <button
      matTooltip="{{
        isAuthorised
          ? 'Download ' + viewStage === 'completed'
            ? 'call'
            : 'Screener files'
          : 'Please login to download files'
      }}"
      *ngIf="downloads$ | async as downloads"
      (click)="handleDownload($event, downloads.active)"
    >
      <span>
        <div *ngIf="downloads.active">{{ downloads.progress }}%</div></span
      >
      <mat-icon>
        {{
          downloads.active
            ? 'downloading'
            : downloadFinished
            ? 'download_done'
            : 'download'
        }}
      </mat-icon>
    </button>
  </ng-container>`,
  styles: [
    `
      button {
        border: none;
        background: transparent;
        cursor: pointer;
        display: flex;
      }

      span {
        color: #333;
        padding-top: 6px;
      }

      mat-icon {
        color: #b4becb;
      }
    `,
  ],
  providers: [ExpertFilesStateService],
})
export class ExpertScreenerBtnComponent implements OnInit, OnDestroy {
  @Input() fill = '#B4BECB';
  @Input() files: ExpertFile[];
  @Input() viewStage: string;
  @Input() isAuthorised: boolean;

  private destroy$ = new Subject();
  private state$ = this.filesState.state$.pipe(
    share(),
    takeUntil(this.destroy$)
  );
  downloads$ = this.state$.pipe(
    map((p) => this.screenerFiles.map((f) => p[f.fileKey])),
    map((files) => ({
      progress: files.some((p) => p?.progress)
        ? Math.min(...files.map((p) => p?.progress).filter(Boolean))
        : 0,
      active: files.some(Boolean),
    }))
  );
  screenerFiles?: ExpertFile[];
  downloadFinished: boolean;

  constructor(
    private fileStore: FileStoreService,
    private filesState: ExpertFilesStateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    if (this.viewStage === 'completed') {
      this.screenerFiles = (this.files || []).filter((f) =>
        ['zoomRecording', 'zoomTranscript'].includes(f.type)
      );
    } else {
      this.screenerFiles = (this.files || []).filter(
        (f) => f.type === 'screenerResponse'
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  handleDownload(e: Event, active: boolean): void {
    e.preventDefault();
    e.stopPropagation();

    active ? this.cancelDownload() : this.downloadFiles();
  }

  private downloadFiles(): void {
    if (this.isAuthorised) {
      from(this.screenerFiles)
        .pipe(
          tap((file) => this.filesState.dispatch(new Add(file.fileKey))),
          mergeMap((file) =>
            this.fileStore
              .downloadExpertFile(
                file.fileKey,
                `${file.fileName}.${file.fileExtension}`
              )
              .pipe(
                takeUntil(this.state$.pipe(filter((d) => !d[file.fileKey]))),
                tap((event) =>
                  this.filesState.dispatch(
                    new UpdateProgress(file.fileKey, event.percentageComplete)
                  )
                ),
                finalize(() => {
                  this.downloadFinished = true;
                  this.filesState.dispatch(new Delete(file.fileKey));
                })
              )
          )
        )
        .subscribe();
    } else {
      this.dialog
        .open(AuthDialogComponent, {
          width: '400px',
        })
        .afterClosed()
        .subscribe();
    }
  }

  private cancelDownload(): void {
    this.screenerFiles.forEach((f) =>
      this.filesState.dispatch(new Delete(f.fileKey))
    );
  }
}
