import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOpportunity } from '../models/opportunity.interface';

@Injectable()
export class OpportunityService {
  private readonly baseUrl = '/client-portal/opportunities';

  constructor(private http: HttpClient) {}

  getDashboardOpportunities(): Observable<IOpportunity[]> {
    return this.http.get<IOpportunity[]>(this.baseUrl);
  }

  public getOpportunityByDisplayId(
    publicOpportunityId: string
  ): Observable<IOpportunity> {
    return this.http.get<IOpportunity>(
      `${this.baseUrl}/${publicOpportunityId}`
    );
  }

  public getActiveOpportunities(): Observable<IOpportunity[]> {
    return this.http.get<IOpportunity[]>(`${this.baseUrl}/active`);
  }
}
