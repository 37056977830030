<div fxLayout="row" fxLayoutAlign="space-between center">
  <h2 class="primary-color" data-test-id="select-availability-dialog-title">Accept expert</h2>
  <button mat-icon-button mat-dialog-close data-test-id="select-availability-cancel-button">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<div fxLayout="column">
  <p data-test-id="select-availability-dialog-body">
    <span *ngIf="data.readyToAccept.isReady">
      Accept expert for {{data.availability | availabilityDate}} {{data.availability | availabilityTime}}?
    </span>
    <span *ngIf="!data.readyToAccept.isReady">
      {{errorMessage}}
    </span>
  </p>

  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" fxFill>
    <button mat-stroked-button color="primary" mat-dialog-close fxFlex="50%"
      data-test-id="select-availability-dialog-close-button">Close</button>
    <button *ngIf="data.readyToAccept.isReady" mat-flat-button color="primary" (click)="confirm()" fxFlex="50%"
      data-test-id="select-availability-dialog-confirm-button">
      Confirm
    </button>
    <button *ngIf="!data.readyToAccept.isReady" mat-flat-button color="primary" (click)="reload()" fxFlex="50%"
      data-test-id="select-availability-dialog-refresh-button">
      Refresh
    </button>
  </div>
</div>