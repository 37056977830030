<div fxFlex="100%" fxLayout="column" style="margin: auto;" *ngIf="!confirmReason" data-test-id="on-hold-reason-dialog">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h3>Reason for putting this expert on hold</h3>
        <button mat-icon-button mat-dialog-close>
            <mat-icon data-test-id="on-hold-reason-dialog-close">clear</mat-icon>
        </button>
    </div>
    <p>
        Let us know why this expert is being put on hold, so we can re-calibrate our
        searching and improve our recommendations
    </p>
    <mat-form-field style="width: 100%;" appearance="outline" data-test-id="on-hold-reason-dialog-notes">
        <mat-label>On hold notes</mat-label>
        <textarea matInput style="min-height: 200px;  resize: none;" [(ngModel)]="onHoldReason"></textarea>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
        <button fxFlex="50%" type="button" mat-stroked-button mat-dialog-close color="primary"
            class="primaryColor-border">
            Close window
        </button>
        <button fxFlex="50%" type="submit" mat-flat-button color="primary" (click)="confirm()"
            data-test-id="on-hold-reason-dialog-confirm">
            Confirm
        </button>
    </div>
</div>