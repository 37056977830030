<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="unit-cost-container">
  <span *ngIf="['Premium', 'Premium+'].includes(expert.costBand)" class="premium-band" fxLayout="row"
    fxLayoutAlign="start center" data-test-id="expert-unit-cost-band-label">
    {{expert.costBand}}
  </span>

  <span fxLayout="row" fxLayoutAlign="start center" class="unit-cost" [ngClass]="expert.costBand | lowercase"
    fxLayoutGap="4px">
    <mat-icon>timer</mat-icon>
    <span data-test-id="expert-unit-rate">{{expert.unitsPerHour || 1 | i18nPlural:unitsMapping}} p/hr</span>
  </span>
</div>