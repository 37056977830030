<div class="header" fxLayoutAlign="center center">
  <h1>Let's get you started</h1>
</div>

<form fxLayout="column" fxLayoutGap="8px" [formGroup]="salesForm" (submit)="submitRequest()">
  <h4>My details</h4>
  <div fxLayoutGap="8px" fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Full name</mat-label>
      <input matInput placeholder="name" formControlName="fullName">
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>Email address</mat-label>
      <input matInput placeholder="email" formControlName="emailAddress">
    </mat-form-field>
  </div>

  <div fxLayoutGap="8px" fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Organisation name</mat-label>
      <input matInput placeholder="org name" formControlName="accountName">
    </mat-form-field>
    <mat-form-field fxFlex>
      <mat-label>Contact phone number</mat-label>
      <input matInput placeholder="phone number" formControlName="phoneNumber">
    </mat-form-field>
  </div>

  <h4>Additional comments</h4>
  <mat-form-field>
    <textarea matInput style="resize: none" formControlName="additionalComments"
      placeholder="Please provide any additional details"></textarea>
  </mat-form-field>

  <button mat-flat-button fxFill color="primary" [disabled]="loading">
    <mat-icon class="secondaryColor">send</mat-icon>
    Send enquiry
  </button>
</form>