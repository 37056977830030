<app-global-result [omnisearchResponse]="omnisearchResponse" [engagementRequested]="engagementRequested"
  (requestExpertEngagement)="requestEngagement($event, 'booking_global')"
  (requestExpertInformation)="requestEngagement($event, 'additional_info')"
  *ngIf="omnisearchResponse.entity === 'portalExpertsGlobal'">
</app-global-result>

<app-client-result [omnisearchResponse]="omnisearchResponse" [engagementRequested]="engagementRequested"
  (requestExpertEngagement)="requestEngagement($event, 'booking')"
  *ngIf="omnisearchResponse.entity === 'portalExperts'">
</app-client-result>

<app-opportunity-result [omnisearchResponse]="omnisearchResponse" *ngIf="omnisearchResponse.entity === 'opportunities'">
</app-opportunity-result>