import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, map, tap } from 'rxjs/operators';
import { IExpert } from '../../../../../shared/models/expert.interface';

export interface IOpportunityFilterForm {
  geographicTargets: string[];
  profileTypes: string[];
  affiliations: string[];
  expertName?: string;
  bioContent?: string;
}

@Component({
  selector: 'app-expert-filter',
  templateUrl: './expert-filter.component.html',
  styleUrls: ['./expert-filter.component.scss'],
})
export class ExpertFilterComponent implements OnInit, OnChanges {
  @Input() experts: IExpert[];
  @Output() filterExperts = new EventEmitter<IExpert[]>();

  geographiesList: string[] = [];
  profileTypesList: string[] = [];
  affiliationsList: string[] = [];

  filterForm = new UntypedFormGroup({
    geographicTargets: new FormControl([]),
    profileTypes: new FormControl([]),
    affiliations: new FormControl([]),
    expertName: new FormControl(null),
    bioContent: new FormControl(null),
  });

  get formHasValues(): boolean {
    return Object.values(this.filterForm.value).some((val) =>
      Array.isArray(val) ? val.length : !!val
    );
  }

  ngOnInit(): void {
    this.filterForm.valueChanges
      .pipe(
        debounceTime(200),
        map((formValues) => this.filterExpertsImpl(this.experts, formValues)),
        tap((filteredExperts) => this.filterExperts.emit(filteredExperts))
      )
      .subscribe();
  }

  ngOnChanges(): void {
    this.setFormOptions(this.experts);
  }

  resetForm(): void {
    this.filterForm.reset({
      geographicTargets: [],
      profileTypes: [],
      affiliations: [],
    });
  }

  private setFormOptions(experts: IExpert[]): void {
    type DistinctKeysTypes =
      | 'geographicTarget'
      | 'profileType'
      | 'portalAffiliations';

    const getDistinct = (field: DistinctKeysTypes): string[] =>
      [...new Set(experts.flatMap<string>((e) => e[field] || []))]
        .map((e) => `${e}`.trim())
        .sort((a, b) => a.localeCompare(b));

    this.geographiesList = getDistinct('geographicTarget');
    this.profileTypesList = getDistinct('profileType');
    this.affiliationsList = getDistinct('portalAffiliations');

    if (!this.affiliationsList.length) {
      this.filterForm.controls['affiliations'].disable();
    }
  }

  private filterExpertsImpl(
    experts: IExpert[],
    filter: IOpportunityFilterForm
  ): IExpert[] {
    return experts
      .filter((e) =>
        filter.geographicTargets?.length
          ? filter.geographicTargets.includes(e.geographicTarget)
          : true
      )
      .filter((e) =>
        filter.profileTypes?.length
          ? filter.profileTypes.includes(e.profileType)
          : true
      )
      .filter((e) =>
        filter.affiliations?.length
          ? (e.portalAffiliations || []).some((a) =>
              filter.affiliations.includes(a)
            )
          : true
      )
      .filter((e) =>
        filter.expertName
          ? `${e.firstName} ${e.lastName}`
              .toLowerCase()
              .trim()
              .includes(filter.expertName.toLowerCase())
          : true
      )
      .filter((e) =>
        filter.bioContent
          ? `${e.bio}${e.screenerResponse}`
              .toLowerCase()
              .trim()
              .includes(filter.bioContent.toLowerCase())
          : true
      );
  }
}
