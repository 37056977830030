import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  OmnisearchQueryResponse,
  OmnisearchService,
} from '@techspert-io/omnisearch';

@Component({
  selector: 'app-opportunity-result',
  templateUrl: './opportunity-result.component.html',
  styleUrls: ['../omnisearch-result.component.scss'],
})
export class OpportunityResultComponent {
  @Input() omnisearchResponse: OmnisearchQueryResponse;

  get searchTerm(): string {
    return this.omnisearchService.searchForm.get('search').value;
  }

  constructor(
    private router: Router,
    private omnisearchService: OmnisearchService
  ) {}

  goToOpportunity(): void {
    this.omnisearchService.setFocused(false);
    this.omnisearchService.clearRecommendedExperts();
    this.router.navigate(['connect', this.omnisearchResponse.id]);
  }
}
