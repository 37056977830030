import { DOCUMENT } from '@angular/common';
import {
  HttpBackend,
  HttpClient,
  HttpEvent,
  HttpEventType,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';

export const FILESTORE_URL = new InjectionToken<string>('FILESTORE_URL');

export interface IFileProgressEvent {
  fileName: string;
  percentageComplete: number;
  status: 'started' | 'ongoing' | 'complete' | 'failed';
}

@Injectable()
export class FileStoreService {
  private httpNoAuth = new HttpClient(this.httpBackend);

  constructor(
    private httpBackend: HttpBackend,
    private http: HttpClient,
    @Inject(FILESTORE_URL) private filestoreUrl: string,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public downloadExpertFile(
    fileKey: string,
    fileName: string
  ): Observable<IFileProgressEvent> {
    return this.getTemporaryDownloadUrl(fileKey).pipe(
      mergeMap((getUrl) => this.download(getUrl, fileName))
    );
  }

  private download(
    url: string,
    fileName: string
  ): Observable<IFileProgressEvent> {
    return this.httpNoAuth
      .get(url, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        filter((event) => event.type !== HttpEventType.ResponseHeader),
        tap((t) => {
          if (t.type === HttpEventType.Response) {
            const a = this.document.createElement('a');
            a.href = URL.createObjectURL(t.body);
            a.setAttribute('download', fileName);
            a.click();
          }
        }),
        map((event) => this.getProgressEvent(event, fileName)),
        catchError(() => this.handleError(fileName))
      );
  }

  private handleError(fileName: string): Observable<IFileProgressEvent> {
    return of({
      fileName,
      percentageComplete: 0,
      status: 'failed',
    });
  }

  private getProgressEvent(
    event: HttpEvent<unknown>,
    fileName: string
  ): IFileProgressEvent {
    const fileData = {
      fileName,
    };
    switch (event.type) {
      case HttpEventType.Sent:
        return {
          ...fileData,
          percentageComplete: 0,
          status: 'started',
        };

      case HttpEventType.DownloadProgress:
      case HttpEventType.UploadProgress:
        return {
          ...fileData,
          percentageComplete: Math.round((100 * event.loaded) / event.total),
          status: 'ongoing',
        };

      case HttpEventType.Response:
        return {
          ...fileData,
          percentageComplete: 100,
          status: 'complete',
        };
    }
  }

  private getTemporaryDownloadUrl(fileKey: string): Observable<string> {
    return this.http.get<string>('/filestore/presigned', {
      params: { fileKey },
    });
  }
}
